import { useEffect, useRef, useState } from 'react';
import DashHeader from '../components/Dashboard/Header';
import LeftMenuNav from '../components/Dashboard/LeftMenuNav';
import Sidebar from '../components/Dashboard/Sidebar';
import GenerateButton from '../components/Dashboard/GenerateButton';
import MainContent from '../components/Dashboard/MainContent';
import ImageViewer from '../components/Dashboard/ImageViewer';
import { useDashboard } from '../components/Dashboard/DashboardContext';
import { useAuthContext } from '../components/AuthContext';
import DocumentTitle from '../utils/DocumentTitle';

interface DashboardProps {
  title: string;
}

export default function Dashboard({ title }: DashboardProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState('left');
  const {
    authState,
    setCameraImages,
    setSavedImages,
    setPromptExamples,
    setSeed,
    trainingExpanded,
  } = useDashboard();
  const { authenticatedFetch } = useAuthContext();
  const headerButtonRef = useRef<HTMLDivElement>(null);
  const idRef = useRef<string | null>(null);

  useEffect(() => {
    if (!authState.user?.user.id || idRef.current === authState.user.user.id) {
      return;
    }
    idRef.current = authState.user.user.id;

    const grabImages = async () => {
      if (!authState.userToken) {
        return;
      }

      // Create a seed for prompt examples
      const seed = Math.random() * 1000000;
      setSeed(seed);

      // Grab camera images
      const cameraImages = await authenticatedFetch('/image/getImages/', {
        method: 'GET',
        queryParams: { isDeleted: false },
      });

      // Grab saved images
      const savedImages = await authenticatedFetch('/image/getImages/', {
        method: 'GET',
        queryParams: {
          isSaved: true,
          isDeleted: false,
        },
      });

      // Grab prompt examples
      const promptExamples = await authenticatedFetch('/image/getPromptExamples/', {
        method: 'GET',
        queryParams: {
          seed: seed,
        },
      });

      setCameraImages(cameraImages.data);
      setSavedImages(savedImages.data);
      setPromptExamples(promptExamples.data);
    };

    grabImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.user?.user.id]);

  return (
    <div style={{ paddingBottom: '100px' }}>
      <DocumentTitle title={title || 'DreamsnapAI: 🔥 Fire your photographer'} />
      <DashHeader
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        headerButtonRef={headerButtonRef}
        setMenuPosition={setMenuPosition}
      />
      <LeftMenuNav
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        headerButtonRef={headerButtonRef}
        menuPosition={menuPosition}
      />
      <Sidebar />
      {!trainingExpanded && <GenerateButton />}
      <MainContent />
      <ImageViewer />
    </div>
  );
}
