import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import '../../../styles/Dashboard/Folder.css';
import { useDashboard } from '../DashboardContext';
import OutputImageContainer from './OutputImageContainer';
import { debounce } from 'lodash';
import { useSnackbar } from '../../SnackbarContext';
import { useAuthContext } from '../../AuthContext';

export default function SavedFolder() {
  const [search, setSearch] = useState('');
  const { savedImages, setSavedImages } = useDashboard();
  const { showSnackbar } = useSnackbar();
  const { authenticatedFetch } = useAuthContext();
  const prevSearchRef = useRef('');

  const fetchImages = useCallback(
    async (searchTerm: string) => {
      try {
        const images = await authenticatedFetch('/image/getImages/', {
          method: 'GET',
          queryParams: {
            isSaved: true,
            isDeleted: false,
            searchPrompt: searchTerm,
          },
        });
        setSavedImages(images.data);
      } catch {
        showSnackbar('Error fetching images', 'error');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setSavedImages]
  );

  const debouncedFetchImages = useMemo(
    () =>
      debounce((searchTerm) => {
        fetchImages(searchTerm);
      }, 1000),
    [fetchImages]
  );

  useEffect(() => {
    if (search !== prevSearchRef.current) {
      prevSearchRef.current = search;
      if (search) {
        debouncedFetchImages(search);
      } else {
        fetchImages('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, debouncedFetchImages, setSavedImages]);

  return (
    <div>
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="photo_search"
        placeholder="Search your photos by prompt or seed..."
        style={{ display: 'inline-block' }}
      />
      <div style={{ clear: 'both' }}></div>
      {savedImages.length === 0 ? (
        <div style={{ justifyContent: 'center', display: 'flex', marginTop: '50px' }}>
          <h2>No images saved yet ⭐</h2>
        </div>
      ) : (
        <OutputImageContainer images={savedImages} setImages={setSavedImages} folderName="saved" />
      )}
    </div>
  );
}
