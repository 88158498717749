import { useRef, useState } from 'react';
import '../../styles/Dashboard/TrainingTab.css';
import { useSnackbar } from '../SnackbarContext';
import JSZip from 'jszip';
import { ModelType, useDashboard } from './DashboardContext';
import LoadingSpinner from './MainContent/LoadingSpinner';
import { useAuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';

interface CreateModelRowProps {
  trainingTabRef: React.RefObject<HTMLDivElement>;
}

export default function CreateModelRow({ trainingTabRef }: CreateModelRowProps) {
  const { setAuthState, authState, trainingExpanded, setTrainingExpanded, setSelectModelExpanded } =
    useDashboard();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { showSnackbar } = useSnackbar();
  const { authenticatedFetch } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const privateModelsLength = authState.user?.models.filter((model) => !model.isPublic).length;

  // New state for form inputs
  const [formData, setFormData] = useState({
    name: '',
    type: '',
    age: '',
    eyeColor: '',
    ethnicity: '',
  });

  // New state for tracking validation errors
  const [errors, setErrors] = useState({
    name: false,
    type: false,
    age: false,
    eyeColor: false,
    ethnicity: false,
    files: false,
  });

  // Refs for form inputs
  const inputRefs = {
    name: useRef<HTMLInputElement>(null),
    type: useRef<HTMLSelectElement>(null),
    age: useRef<HTMLInputElement>(null),
    eyeColor: useRef<HTMLSelectElement>(null),
    ethnicity: useRef<HTMLSelectElement>(null),
  };

  const toggleTab = () => {
    setTrainingExpanded(!trainingExpanded);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: false })); // Clear error when input changes
  };

  const validateForm = () => {
    console.log(selectedFiles.length);
    const newErrors = {
      name: formData.name.trim() === '',
      type: formData.type === '',
      age: formData.age === '',
      eyeColor: formData.eyeColor === '',
      ethnicity: formData.ethnicity === '',
      files: selectedFiles.length < 10,
    };

    setErrors(newErrors);

    // Focus on the first input with an error
    const errorField = Object.keys(newErrors).find(
      (key) => newErrors[key as keyof typeof newErrors]
    );
    if (errorField && errorField !== 'files') {
      inputRefs[errorField as keyof typeof inputRefs].current?.focus();
    } else if (errorField === 'files') {
      showSnackbar('Please select at least 10 photos', 'warning');
    }

    return !Object.values(newErrors).some((error) => error);
  };
  const createModel = async () => {
    if (!validateForm()) {
      // Form is invalid
      return;
    }

    setIsLoading(true);

    // Clear any previous training timers that weren't cleared
    localStorage.removeItem('fluxModelStartTime');

    try {
      const zip = new JSZip();
      selectedFiles.forEach((file, index) => {
        zip.file(`image_${index + 1}.${file.name.split('.').pop()}`, file);
      });

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      const newFormData = new FormData();
      newFormData.append('zipFile', zipBlob, 'images.zip');
      newFormData.append(
        'modelData',
        JSON.stringify({
          name: formData.name,
          type: formData.type,
          age: formData.age,
          eyeColor: formData.eyeColor,
          ethnicity: formData.ethnicity,
        })
      );

      const response = await authenticatedFetch('/inference/createModel', {
        method: 'POST',
        body: newFormData,
      });

      if (!response.requestId) {
        throw new Error('Failed to create model');
      }

      // Create temporary model
      const newModel: ModelType = {
        modelId: '',
        requestId: response.requestId,
        modelName: formData.name,
        gender: '',
        objectType: formData.type,
        isPublic: false,
        imageUrl: '',
        modelUrl: '',
        isLoading: true,
      };

      setAuthState((prev) => ({
        ...prev,
        user: {
          ...prev.user!,
          models: [newModel, ...prev.user!.models],
        },
      }));

      showSnackbar('Training model now!', 'success');
      setSelectModelExpanded(true);
      setIsLoading(false);
      setTrainingExpanded(false);

      // Clear form data
      setFormData({
        name: '',
        type: '',
        age: '',
        eyeColor: '',
        ethnicity: '',
      });
    } catch (error) {
      console.error('Error creating model:', error);
      showSnackbar('Failed to create model. Please try again.', 'error');
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const upgradePlan = () => {
    navigate('/billing');
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files));
      setErrors((prev) => ({ ...prev, files: false }));
    }
  };

  return (
    <div ref={trainingTabRef}>
      <div>
        <div className="training_mode" style={{ paddingBottom: '28px' }}>
          <h3
            className={`h3_create_model h3_input expands_next_div ${trainingExpanded ? 'expanded' : ''}`}
            onClick={toggleTab}
          >
            {privateModelsLength === 0 ? 'Create your first AI model' : 'Create new AI model'}
          </h3>

          <div className={`expandable_div ${trainingExpanded ? 'expanded' : ''}`}>
            <p style={{ fontSize: '12px', opacity: 0.5 }}>
              You have {authState.user?.user.subscription.availableModels} model
              {authState.user?.user.subscription.availableModels != 1 ? 's' : ''} left to create on
              your current plan. To create more AI models,{' '}
              <a onClick={upgradePlan} className="action-upgrade">
                upgrade your plan
              </a>
              .
            </p>
            <p style={{ fontSize: '12px', opacity: 0.5 }}>
              An AI model is an AI representation of you. The AI model you create is private and
              only usable by you. You create one below by uploading photos of yourself. After
              creating a model it takes about 5 minutes to train it on your photos. You will see
              when it's ready in the select model tab.
            </p>

            <p>
              <span className="label">Name</span>
              <input
                ref={inputRefs.name}
                className={`input_create_model_name ${errors.name ? 'inputError' : ''}`}
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Model's name"
              />
            </p>

            <p>
              <span className="label">Type</span>
              <select
                ref={inputRefs.type}
                className={`select_create_model_gender ${errors.type ? 'inputError' : ''}`}
                name="type"
                value={formData.type}
                onChange={handleInputChange}
              >
                <option></option>
                <option value="Man">Man</option>
                <option value="Woman">Woman</option>
                {/* <option value="couple">Couple / Two people</option>
                <option value="dog">Dog</option>
                <option value="cat">Cat</option>
                <option value="o">Other</option>
                <option></option>
                <option value="style">Style</option>
                <option value="product">Product</option>
                <option value="clothing">Clothing</option>
                <option value="object">Object</option> */}
              </select>
            </p>
            <p>
              <span className="label">Age</span>
              <input
                ref={inputRefs.age}
                type="number"
                maxLength={2}
                name="age"
                value={formData.age}
                onChange={handleInputChange}
                className={`input_create_model_age ${errors.age ? 'inputError' : ''}`}
              />
            </p>
            <p>
              <span className="label">Eye color</span>
              <select
                ref={inputRefs.eyeColor}
                className={`select_create_model_eye_color ${errors.eyeColor ? 'inputError' : ''}`}
                name="eyeColor"
                value={formData.eyeColor}
                onChange={handleInputChange}
              >
                <option></option>
                <option>Brown</option>
                <option>Blue</option>
                <option>Hazel</option>
                <option>Green</option>
                <option value="none">none</option>
              </select>
            </p>
            <p>
              <span className="label">
                Ethnicity
                <span
                  className="info"
                  data-tooltip="Note: if for ex. Chinese-Thai, better to select East Asian than South East Asian. Use common sense."
                ></span>
              </span>
              <select
                ref={inputRefs.ethnicity}
                className={`select_create_model_ethnicity ${errors.ethnicity ? 'inputError' : ''}`}
                name="ethnicity"
                value={formData.ethnicity}
                onChange={handleInputChange}
              >
                <option></option>
                <option>White</option>
                <option>Black</option>
                <option value="Asian American">Asian American</option>
                <option value="East Asian">East Asian (Chinese, Japanese, Korean)</option>
                <option value="South East Asian">South East Asian (Thai, Indonesian)</option>
                <option value="South Asian">South Asian (Indian)</option>
                <option value="Middle Eastern">Middle Eastern (Arabic)</option>
                <option>Pacific (Polynesian)</option>
                <option>Hispanic</option>
                <option value="none">none</option>
              </select>
            </p>

            <p>
              <span className="label">Select 10+ photos</span>
            </p>

            <input
              ref={fileInputRef}
              name="file"
              multiple
              type="file"
              className="input_training_uploader"
              accept=".jpg,.jpeg,.webp,.png,.avif"
              onChange={handleFileChange}
            />

            <div className="training_examples">
              <p>
                <strong>✅&nbsp;&nbsp;Good photos</strong>
              </p>
              <p
                style={{
                  opacity: 0.75,
                  fontSize: '14px',
                  marginTop: '-14px',
                  marginBottom: '3.5px',
                }}
              >
                Current photos of yourself, mix of close ups and full body shots in a variety of
                places, angles, clothes and expressions, make sure they're recent!
              </p>
              <div className="training_examples_scroller good">
                <div className="training_example">
                  <img width={100} height={100} src="/trainingExamples/trainingExample1.jpg" />
                </div>
                <div className="training_example">
                  <img width={100} height={100} src="/trainingExamples/trainingExample2.jpg" />
                </div>
                <div className="training_example">
                  <img width={100} height={100} src="/trainingExamples/trainingExample3.jpg" />
                </div>
                <div className="training_example">
                  <img width={100} height={100} src="/trainingExamples/trainingExample4.jpg" />
                </div>
              </div>
              <p>
                <strong>❌&nbsp;&nbsp;Bad photos</strong>
              </p>
              <p
                style={{
                  opacity: 0.75,
                  fontSize: '14px',
                  marginTop: '-14px',
                  marginBottom: '3.5px',
                }}
              >
                IG screenshots, lots of makeup, filters, low variety, group photos, other
                people/animals in photo, sunglasses, hats, face cutOff or not visible and too old
                photos (because you might look different now)
              </p>
              <div className="training_examples_scroller bad">
                <div className="training_example">
                  <img width={100} height={100} src={'/trainingExamples/trainingExample5.png'} />
                </div>
                <div className="training_example">
                  <img width={100} height={100} src="/trainingExamples/trainingExample6.jpg" />
                </div>
                <div className="training_example">
                  <img width={100} height={100} src="/trainingExamples/trainingExample7.jpg" />
                </div>
                <div className="training_example">
                  <img width={100} height={100} src="/trainingExamples/trainingExample8.jpg" />
                </div>
              </div>
            </div>

            <div className="div_training_uploader" onClick={handleUploadClick}>
              <div className="loading_spinner">
                <svg className="loading_spinner_svg" viewBox="25 25 50 50">
                  <circle
                    className="loading_spinner_circle"
                    cx="50"
                    cy="50"
                    r="22"
                    fill="none"
                  ></circle>
                  <circle
                    className="loading_spinner_circle loading_spinner_circle--spinner"
                    cx="50"
                    cy="50"
                    r="22"
                    fill="none"
                  ></circle>
                </svg>
                <div className="loading_message"></div>
              </div>
              <div className="div_training_uploader_center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon"
                >
                  <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4"></path>
                  <polyline points="17 8 12 3 7 8"></polyline>
                  <line x1="12" y1="3" x2="12" y2="15"></line>
                </svg>
                <br />

                <span className="idle_text">
                  <br />
                  {selectedFiles.length === 0
                    ? 'Click here to select at least 10 photos of your model'
                    : `${selectedFiles.length} files selected ( 10 minimum )`}
                  <br />
                  <br />
                  At least 20 for high resemblance
                  <br />
                  <br /> Hold ⇧ to select multiple files
                </span>
              </div>
            </div>

            <div style={{ marginLeft: '21px', marginRight: '21px' }}>
              <div
                style={{ marginLeft: 0 }}
                className={`button gradient ${isLoading || authState.user?.user.subscription.availableModels === 0 ? 'disabled' : ''}`}
                onClick={
                  !isLoading && (authState.user?.user.subscription.availableModels || 0) > 0
                    ? createModel
                    : undefined
                }
              >
                {isLoading ? (
                  <div
                    style={{
                      height: '10%',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <LoadingSpinner size={20} />
                  </div>
                ) : (
                  'Create model'
                )}
              </div>
            </div>
            <p style={{ fontSize: '12px', opacity: 0.5, marginTop: '7px', textAlign: 'center' }}>
              By creating a model, you agree to our{' '}
              <a target="_blank" href="/legal">
                TOS
              </a>{' '}
              and confirm that you are creating a model of yourself or have obtained explicit
              consent from any individuals whose likeness you are using.
            </p>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
