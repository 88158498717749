import { useState } from 'react';
import { useDashboard } from './DashboardContext';

export default function SetPreferencesRow() {
  const { photoPrompt, setPhotoPrompt } = useDashboard();
  const [expanded, setExpanded] = useState(false);

  const toggleTab = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="parameters">
      <h3 className={`h3_input expands_next_div ${expanded ? 'expanded' : ''}`} onClick={toggleTab}>
        Set preferences
      </h3>

      <div className={`expandable_div ${expanded ? 'expanded' : ''}`}>
        <p className="amount_of_photos">
          <span className="label">amount of photos</span>
          <select
            className="amount_of_photos select_amount_of_photos"
            name="amount_of_photos"
            value={photoPrompt.photoCount}
            onChange={(e) =>
              setPhotoPrompt({ ...photoPrompt, photoCount: parseInt(e.target.value) })
            }
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="4">4</option>
            <option value="8" disabled>
              8
            </option>
            <option value="16" disabled>
              16
            </option>{' '}
          </select>
        </p>

        <p>
          <span className="label">Orientation</span>
          <select
            className="orientation"
            name="orientation"
            value={photoPrompt.photoSize}
            onChange={(e) => setPhotoPrompt({ ...photoPrompt, photoSize: e.target.value })}
          >
            <option value="portrait_4_3">▮ Portrait [best]</option>
            <option value="landscape_4_3">▬ Landscape</option>
            <option value="square_hd">◼ Square</option>
          </select>
        </p>
      </div>
    </div>
  );
}
