import { useState } from 'react';
import PricingPlan from './PricingPlan';
import '../../../styles/Pricing.css';
import { useApp } from '../../AppContext';

interface PricingProps {
  header?: boolean;
  manageSubscription?: boolean;
  homePage?: boolean;
}

export default function Pricing({
  header,
  manageSubscription = false,
  homePage = false,
}: PricingProps) {
  const { pricing } = useApp();
  const [isYearly, setIsYearly] = useState(false);

  const switchPlan = () => {
    setIsYearly(!isYearly);
  };

  return (
    <div>
      <div className="pricing" id="pricing">
        {header && (
          <center>
            <h2>Plans &amp; pricing</h2>
          </center>
        )}
        <div className="pricing-container">
          <div className="toggle-switch">
            <button
              style={{ position: 'relative', zIndex: 999 }}
              className={`monthly ${!isYearly ? 'active' : ''}`}
              onClick={() => setIsYearly(false)}
            >
              Monthly
            </button>
            <button
              className={`yearly ${isYearly ? 'active' : ''}`}
              style={{ position: 'relative', zIndex: 999 }}
              onClick={() => setIsYearly(true)}
            >
              🔥 Yearly: get 6+ months free
            </button>
          </div>
          <div className="pricing-wrapper">
            {pricing.map((plan, index) => (
              <PricingPlan
                key={index}
                plan={plan}
                isYearly={isYearly}
                isPopular={plan.planName === 'Premium Plan'}
                switchPlan={switchPlan}
                manageSubscription={manageSubscription}
                homePage={homePage}
              />
            ))}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}
