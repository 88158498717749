import { forwardRef, useCallback, useEffect, useState } from 'react';
import '../../../styles/Dashboard/OutputImage.css';
import { ImageType, useDashboard } from '../DashboardContext';
import CustomFetch from '../../../utils/FetchHook';
import LoadingSpinner from './LoadingSpinner';
import { useSnackbar } from '../../SnackbarContext';
import { useModalAlert } from '../../ModalAlertContext';
import { useAuthContext } from '../../AuthContext';

interface OutputImageProps {
  image: ImageType;
  setImages: React.Dispatch<React.SetStateAction<ImageType[]>>;
  folderName: string;
}

export const OutputImage = forwardRef<HTMLDivElement, OutputImageProps>(
  ({ image, setImages, folderName }, ref) => {
    const {
      setPhotoPrompt,
      showImageViewer,
      authState,
      setSavedImages,
      setCameraImages,
      setIsLoading,
    } = useDashboard();
    const [isMobile, setIsMobile] = useState(false);
    const [isHovering, setIsHovering] = useState(false);
    const { showSnackbar } = useSnackbar();
    const { showAlert } = useModalAlert();
    const { authenticatedFetch } = useAuthContext();

    const handleDownload = async (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!image.imageLink) {
        console.error('No image link provided');
        showSnackbar('No image link available for download.', 'error');
        return;
      }

      try {
        const workerUrl = 'https://image.dreamsnapai.com';
        const response = await fetch(`${workerUrl}?url=${encodeURIComponent(image.imageLink)}`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const contentType = response.headers.get('Content-Type');
        if (!contentType || !contentType.startsWith('image/')) {
          throw new Error('The response is not an image');
        }

        // Get the filename from the Content-Disposition header, if available
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'image.jpg';
        if (contentDisposition) {
          const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
          if (filenameMatch) {
            filename = filenameMatch[1];
          }
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Use click() directly on the link
        link.click();

        // Clean up
        window.URL.revokeObjectURL(url);
      } catch {
        showSnackbar('An error occurred while downloading the image. Please try again.', 'error');
      }
    };

    const handleUpscaleImage = async (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      if (!image.imageLink) return;

      showAlert(
        'Are you sure you want to upcale this image? It will cost 10 tokens.',
        async () => {
          const { requestId } = await authenticatedFetch('/inference/upscaleImage', {
            method: 'POST',
            body: JSON.stringify({
              imageUrl: image.imageLink,
              prompt: image.prompt,
              modelId: image.modelId,
              modelName: image.modelName,
            }),
          });

          // Create placeholder loading images
          const tempImages = Array.from({ length: 1 }, () => ({
            imageId: Math.random().toString(36).substring(7),
            requestId: requestId,
            userId: authState.user?.user.id || '',
            modelId: image.modelId || '',
            imageLink: '',
            isLoading: true,
            description: '',
            modelName: image.modelName || '',
            prompt: image.prompt,
            negativePrompt: '',
            seed: '',
            width: 512,
            height: 768,
            generateTimeMs: 0,
            createdAt: new Date().toISOString(),
            upscaled: true,
            isSaved: false,
            isDeleted: false,
          }));

          // Update camera images with loading images
          setCameraImages((prev) => [...tempImages, ...prev]);
        },
        () => {},
        image.imageLink || undefined
      );
    };

    const handleCopyPrompt = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      showSnackbar("Copied to 'Take a photo' input", 'success');
      setPhotoPrompt((prev) => ({ ...prev, prompt: image.prompt }));
    };

    const handleImageClick = () => {
      if (!isMobile) {
        showImageViewer(image.imageLink || '', image.prompt, folderName);
      } else {
        setIsHovering(!isHovering);
      }
    };

    const formatRelativeTime = (dateString: string): string => {
      const date = new Date(dateString);
      const now = new Date();
      const diffInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);

      if (diffInSeconds < 60) return 'just now';
      if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
      if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;
      return `${Math.floor(diffInSeconds / 86400)}d ago`;
    };

    const formatGenerationTime = (ms: number): string => {
      const seconds = Math.round(ms / 1000);
      return `${seconds}s`;
    };

    const deleteImage = async (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      showAlert(
        'Are you sure you want to delete this image?',
        async () => {
          // Delete the image
          await authenticatedFetch('/image/deleteImage', {
            method: 'POST',
            body: JSON.stringify({ imageId: image.imageId }),
          });

          // Update local data
          setSavedImages((prevImages) => {
            return prevImages.filter((prevImage) => prevImage.imageId !== image.imageId);
          });
          setCameraImages((prevImages) => {
            return prevImages.filter((prevImage) => prevImage.imageId !== image.imageId);
          });
        },
        () => {},
        image.imageLink || undefined
      );
    };

    const saveImage = async (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();

      // Call API
      await authenticatedFetch('/image/saveImage', {
        method: 'POST',
        body: JSON.stringify({ imageId: image.imageId, save: !image.isSaved }),
      });

      if (!image.isSaved) {
        // Add to savedImages
        setSavedImages((prevImages) => {
          return [{ ...image, isSaved: true }, ...prevImages];
        });

        if (folderName === 'camera') {
          setImages((prevImages) => {
            return prevImages.map((prevImage) => {
              if (prevImage.imageId === image.imageId) {
                return { ...prevImage, isSaved: true };
              }
              return prevImage;
            });
          });
        }
      }

      if (image.isSaved) {
        // Remove from savedImages
        setSavedImages((prevImages) => {
          return prevImages.filter((prevImage) => prevImage.imageId !== image.imageId);
        });

        if (folderName === 'camera') {
          setImages((prevImages) => {
            return prevImages.map((prevImage) => {
              if (prevImage.imageId === image.imageId) {
                return { ...prevImage, isSaved: false };
              }
              return prevImage;
            });
          });
        } else {
          setCameraImages((prevImages) => {
            return prevImages.map((prevImage) => {
              if (prevImage.imageId === image.imageId) {
                return { ...prevImage, isSaved: false };
              }
              return prevImage;
            });
          });
        }
      }
      showSnackbar(image.isSaved ? 'Image unsaved' : 'Image saved', 'success');
    };

    useEffect(() => {
      const checkIfMobile = () => {
        setIsMobile(window.innerWidth <= 768);
      };

      checkIfMobile();
      window.addEventListener('resize', checkIfMobile);

      return () => window.removeEventListener('resize', checkIfMobile);
    }, []);

    const fetchData = useCallback(async () => {
      if (!image.isLoading) {
        return 'finished';
      }

      try {
        const response = await authenticatedFetch('/inference/checkRequestStatus', {
          method: 'POST',
          body: JSON.stringify({ requestId: image.requestId, upscale: !!image.upscaled }),
        });

        if (response.status === 'in_progress') {
          return 'in_progress';
        }

        setImages((prevImages) =>
          prevImages.map((prevImage) =>
            prevImage.imageId === image.imageId
              ? {
                  ...prevImage,
                  imageLink: image.upscaled
                    ? response.image.url
                    : response.images[image.countIndex || 0].url,
                  isLoading: false,
                  generateTimeMs: response.timings.inference * 1000,
                }
              : prevImage
          )
        );

        return 'finished';
      } catch {
        showSnackbar('Error fetching data', 'error');
        return 'error';
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image, authState.userToken, CustomFetch, setImages]);

    useEffect(() => {
      const intervalId: NodeJS.Timeout = setInterval(async () => {
        const result = await fetchData();
        if (result === 'finished' || result === 'error') {
          setIsLoading(false);
          clearInterval(intervalId);
        }
      }, 10000);

      // Initial fetch
      fetchData();

      return () => clearInterval(intervalId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);

    return (
      <div
        ref={ref}
        className={`div_output_image camera ${image.upscaled ? 'upscaled' : ''}  ${image.isLoading ? 'processing' : 'finished'} ${image.isSaved ? 'saved' : ''} ${isHovering ? 'active' : ''}`}
        onClick={handleImageClick}
        onMouseEnter={() => !isMobile && !image.isLoading && setIsHovering(true)}
        onMouseLeave={() => !isMobile && !image.isLoading && setIsHovering(false)}
      >
        {!image.isLoading && (
          <div>
            <span className="action-trash-photo " onClick={deleteImage}>
              <svg width="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fill="#fff"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.10002 5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4.06055L4.88474 20.1871C4.98356 21.7682 6.29471 23 7.8789 23H16.1211C17.7053 23 19.0164 21.7682 19.1153 20.1871L19.9395 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19.0073C19.0018 4.99995 18.9963 4.99995 18.9908 5H16.9C16.4367 2.71776 14.419 1 12 1C9.58104 1 7.56329 2.71776 7.10002 5ZM9.17071 5H14.8293C14.4175 3.83481 13.3062 3 12 3C10.6938 3 9.58254 3.83481 9.17071 5ZM17.9355 7H6.06445L6.88085 20.0624C6.91379 20.5894 7.35084 21 7.8789 21H16.1211C16.6492 21 17.0862 20.5894 17.1192 20.0624L17.9355 7ZM14.279 10.0097C14.83 10.0483 15.2454 10.5261 15.2068 11.0771L14.7883 17.0624C14.7498 17.6134 14.2719 18.0288 13.721 17.9903C13.17 17.9517 12.7546 17.4739 12.7932 16.9229L13.2117 10.9376C13.2502 10.3866 13.7281 9.97122 14.279 10.0097ZM9.721 10.0098C10.2719 9.97125 10.7498 10.3866 10.7883 10.9376L11.2069 16.923C11.2454 17.4739 10.83 17.9518 10.2791 17.9903C9.72811 18.0288 9.25026 17.6134 9.21173 17.0625L8.79319 11.0771C8.75467 10.5262 9.17006 10.0483 9.721 10.0098Z"
                ></path>
              </svg>
            </span>
            <span
              className={`action-save-photo ${image.isSaved ? 'saved' : ''}`}
              onClick={saveImage}
            >
              <svg
                aria-hidden="true"
                width="25"
                focusable="false"
                data-prefix="fas"
                data-icon="heart"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="svg-inline--fa fa-heart fa-w-16 fa-9x"
              >
                <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path>
              </svg>
            </span>
            <div className="div_output_image_buttons">
              <div className="action-download-image" onClick={handleDownload}>
                <span></span>
                <br />
                Download
              </div>
              <div
                className="action-view-image"
                onClick={(e) => {
                  e.stopPropagation();
                  showImageViewer(image.imageLink || '', image.prompt, folderName);
                }}
              >
                <span></span>
                <br />
                View
              </div>
              <br />
              <div className="action-copy-prompt" onClick={handleCopyPrompt}>
                <span></span>
                <br />
                Copy prompt
              </div>
              <div className="action-upscale" onClick={handleUpscaleImage}>
                <span></span>
                <br />
                Upscale
              </div>
            </div>
          </div>
        )}
        <div className="div_output_image_meta">
          {image.description}
          <br />
          {image.modelName !== '' ? image.modelName : 'Model'},{' '}
          {formatRelativeTime(image.createdAt)}, took {formatGenerationTime(image.generateTimeMs)}
        </div>
        <div className="div_output_image_container">
          {!image.isLoading ? (
            <img width="512" height="768" className="output_image" src={image.imageLink || ''} />
          ) : (
            <div className="div_output_image_container">
              <img width="512" height="768" className="output_image" src={''} />
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <LoadingSpinner />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);
