import PackItem from '../../Home/Packs/PackItem';
import '../../../styles/Dashboard/Folder.css';
import { useApp } from '../../AppContext';

export default function PacksFolder() {
  const { photoPacks } = useApp();

  return (
    <div
      className="div_output_images templates"
      style={{ opacity: 1, display: 'block', paddingBottom: '200px' }}
    >
      {photoPacks.map((pack) => (
        <PackItem key={pack.id} clickEvent pack={pack} />
      ))}
    </div>
  );
}
