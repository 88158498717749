import { useCallback, useRef } from 'react';
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile';
import customFetch from '../../../utils/FetchHook';

interface TurnstileWrapperProps {
  onVerify: (token: string) => void;
  onError?: () => void;
  onExpire?: () => void;
}

const verifyTurnstileToken = async (token: string) => {
  try {
    const response = await customFetch({
      endpoint: '/user/verifyTurnstile',
      options: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      },
    });
    return response;
  } catch (error) {
    console.error('Error verifying Turnstile token:', error);
    throw error;
  }
};

const TurnstileWrapper: React.FC<TurnstileWrapperProps> = ({ onVerify, onError, onExpire }) => {
  const turnstileRef = useRef<TurnstileInstance>(null);

  const resetTurnstile = useCallback(() => {
    if (turnstileRef.current) {
      turnstileRef.current.reset();
    }
  }, []);

  const handleSuccess = useCallback(
    async (token: string) => {
      try {
        // Verify the token with your backend before calling onVerify
        const response = await verifyTurnstileToken(token);
        if (response.success) {
          console.log('Turnstile verification successful');
          onVerify(token);
        } else {
          console.error('Turnstile verification failed');
          onError?.();
        }
      } catch (error) {
        console.error('Error during Turnstile verification:', error);
        onError?.();
      }
    },
    [onVerify, onError]
  );

  const handleError = useCallback(() => {
    console.error('Turnstile error');
    resetTurnstile();
    onError?.();
  }, [onError, resetTurnstile]);

  const handleExpire = useCallback(() => {
    console.log('Turnstile expired');
    resetTurnstile();
    onExpire?.();
  }, [onExpire, resetTurnstile]);

  return (
    <div style={{ display: 'block', marginLeft: '14px', marginTop: '10px', marginBottom: '10px' }}>
      <Turnstile
        ref={turnstileRef}
        siteKey="0x4AAAAAAAxFqUh2mw-sU7xy"
        options={{
          theme: 'dark',
          size: 'normal',
        }}
        onSuccess={handleSuccess}
        onError={handleError}
        onExpire={handleExpire}
      />
    </div>
  );
};

export default TurnstileWrapper;
