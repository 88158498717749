import '../../../styles/FooterBox.css';

export default function FooterBox() {
  const scrollToTopAndFocus = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer-cta-box">
      <a onClick={scrollToTopAndFocus}>
        <img loading="lazy" width="392" height="500" src="/footer/footer1.jfif" alt="Footer CTA" />
      </a>
      <div className="footer-cta-box-left">
        <h4>Start taking AI photos now</h4>
        <p>
          Generate photorealistic images of people with AI. Take stunning photos of people with the
          first AI Photographer! Generate photo and video content for your social media with AI.
          Save time and money and do an AI photo shoot from your laptop or phone instead of hiring
          an expensive photographer
        </p>
        <div className="benefit">
          ✏️ <a href="#selfies">Upload selfies</a> and create your own <a href="#model">AI model</a>
        </div>
        <div className="benefit">
          📸 <a href="#selfies">Take 100% AI photos</a> in any pose, place or action
        </div>
        <div className="benefit">
          ❤️ <a href="#selfies">Run photo packs</a> like AI Yearbook and Old Money
        </div>
        <div className="benefit">
          🎭 <a href="#personas">Create multiple personas</a> for diverse content creation
        </div>
        <div className="benefit">
          🌍 <a href="#locations">Transport yourself</a> to exotic locations without leaving home
        </div>
        <div className="benefit">
          👚 <a href="#wardrobe">Access an infinite AI wardrobe</a> for countless outfit
          combinations
        </div>
        <div className="benefit">
          🎨 <a href="#styles">Experiment with various artistic styles</a> and photo filters
        </div>
        <br />
        <button onClick={scrollToTopAndFocus} className="button">
          Create your AI model +
        </button>
      </div>
    </div>
  );
}
