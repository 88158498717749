import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import '../styles/ModalAlert.css';

type ModalContextType = {
  showAlert: (
    message: string,
    onConfirm: () => void,
    onCancel: () => void,
    imageUrl?: string | undefined
  ) => void;
  hideAlert: () => void;
};

type ModalStateType = {
  isOpen: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  imageUrl?: string;
};

type ModalAlertProviderProps = {
  children: ReactNode;
};

type ModalAlertProps = {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
  onClose: () => void;
  imageUrl?: string;
};

const ModalAlertContext = createContext<ModalContextType | undefined>(undefined);

// Provider component
export function ModalAlertProvider({ children }: ModalAlertProviderProps) {
  const [alertState, setAlertState] = useState<ModalStateType>({
    isOpen: false,
    message: '',
    onConfirm: () => {},
    onCancel: () => {},
    imageUrl: undefined,
  });

  const showAlert = useCallback(
    (
      message: string,
      onConfirm: () => void,
      onCancel: () => void,
      imageUrl: string | undefined
    ) => {
      setAlertState({ isOpen: true, message, onConfirm, onCancel, imageUrl });
    },
    []
  );

  const hideAlert = useCallback(() => {
    setAlertState((prev) => ({ ...prev, isOpen: false }));
  }, []);

  return (
    <ModalAlertContext.Provider value={{ showAlert, hideAlert }}>
      {children}
      {alertState.isOpen && (
        <ModalAlert
          message={alertState.message}
          onConfirm={alertState.onConfirm}
          onCancel={alertState.onCancel}
          onClose={hideAlert}
          imageUrl={alertState.imageUrl}
        />
      )}
    </ModalAlertContext.Provider>
  );
}

// Custom hook to use the modal
export const useModalAlert = () => {
  const context = useContext(ModalAlertContext);
  if (!context) {
    throw new Error('useModalAlert must be used within a ModalAlertProvider');
  }
  return context;
};

function ModalAlert({
  message,
  onConfirm,
  onCancel,
  onClose,
  imageUrl = undefined,
}: ModalAlertProps) {
  return (
    <div>
      <div className="modal_alert_backdrop" />
      <div className="modal_alert rainbow">
        {imageUrl && <img style={{ width: '25%' }} src={imageUrl} alt="Alert" />}
        <br />
        <br />
        <strong>{message}</strong>
        <br />
        <br />
        <div
          className="button red half reverse"
          onClick={() => {
            onCancel();
            onClose();
          }}
        >
          Cancel
        </div>
        <div
          className="button gradient half"
          onClick={() => {
            onConfirm();
            onClose();
          }}
        >
          Confirm
        </div>
        <div style={{ clear: 'both' }}></div>
      </div>
    </div>
  );
}
