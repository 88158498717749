import '../../../styles/Testimonials.css';

const VerifiedPurchase = () => (
  <span
    style={{
      marginTop: '7px',
      float: 'right',
      opacity: 0.5,
      filter: 'saturate(0)',
      fontSize: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    }}
  >
    ✅ Verified purchase
  </span>
);

interface TestimonialProps {
  stars: string;
  content: string;
  name: string;
}

const Testimonial = ({ stars, content, name }: TestimonialProps) => (
  <div className="testimonial">
    <div className="stars">{stars}</div>
    <p>{content}</p>
    <br />
    <p>
      <strong>{name}</strong>
      <VerifiedPurchase />
    </p>
  </div>
);

export default function Testimonials() {
  return (
    <div id="testimonials" className="testimonials feature">
      <h2 style={{ textAlign: 'center' }}>
        Users are thrilled with their AI-generated masterpieces
      </h2>

      <Testimonial
        stars="⭐️⭐️⭐️⭐️⭐️"
        content="DreamSnapAI has revolutionized my creative process! As a graphic designer, I use it to mock up concepts for clients in record time. The ability to generate diverse, high-quality images has become an indispensable part of my workflow. Absolutely game-changing!"
        name="Alex"
      />

      <Testimonial
        stars="⭐️⭐️⭐️⭐️⭐️"
        content="I'm blown away by DreamSnap's versatility! I've created stunning travel photos for my blog without leaving home, explored countless fashion combinations for my online store, and even designed unique party invitations. The possibilities seem endless!"
        name="Sophia"
      />

      <Testimonial
        stars={'⭐️⭐️⭐️⭐️'}
        content="DreamSnapAI exceeded all my expectations! The intuitive interface made it easy to start creating amazing images right away. I've discovered a whole new level of creativity I didn't know I had. From professional headshots to fantastical scenes, DreamSnapAI delivers stunning results every time. It's addictively fun and incredibly powerful!"
        name="Marco"
      />
    </div>
  );
}
