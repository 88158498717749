import '../../../styles/DetailFeature.css';

export default function DetailFeature() {
  return (
    <div>
      <div id="detail" className="detail feature" style={{ textAlign: 'center' }}>
        <h2 style={{ textAlign: 'center' }}>The most detailed AI image generator for people</h2>
        <p style={{ textAlign: 'center' }}>
          DreamSnapAI is capable of extreme detail going from wide shots to extreme close ups and
          photography.
        </p>
        <br />
        <br />
        <img loading="lazy" src="zoomExample/zoom1.jpg" width="244" height="366" alt="Detail 1" />
        <img loading="lazy" src="zoomExample/zoom2.jpg" width="244" height="366" alt="Detail 2" />
        <img loading="lazy" src="zoomExample/zoom3.jpg" width="244" height="366" alt="Detail 3" />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}
