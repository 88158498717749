import '../../../styles/Packs.css';
import { PhotoPack } from '../../AppContext';
import { useAuthContext } from '../../AuthContext';
import { useDashboard } from '../../Dashboard/DashboardContext';
import { useModalAlert } from '../../ModalAlertContext';
import { useSnackbar } from '../../SnackbarContext';

interface PackItemProps {
  clickEvent?: boolean;
  pack: PhotoPack;
}

export default function PackItem({ pack, clickEvent = false }: PackItemProps) {
  const { selectedModel, authState, setActiveFolder, setCameraImages, setAuthState } =
    useDashboard();
  const { showAlert } = useModalAlert();
  const { authenticatedFetch } = useAuthContext();
  const { showSnackbar } = useSnackbar();

  const handleClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();

    // Check if user has enough tokens
    if (Number(authState.user?.user.tokens) < pack.photoCount) {
      showSnackbar('You do not have enough tokens to use this photo pack.', 'error');
      return;
    }

    showAlert(
      `Would you like to take ${pack.photoCount} photos in the [${pack.name}] photo pack with model [${selectedModel?.modelName}]? This will cost ${pack.photoCount} photo credits.`,
      async () => {
        // Call API
        const response = await authenticatedFetch('/inference/createPhotoPack', {
          method: 'POST',
          body: JSON.stringify({
            modelUrl: selectedModel?.modelUrl,
            photoPackId: pack.id,
            modelId: selectedModel?.modelId,
            modelName: selectedModel?.modelName,
            modelGender: selectedModel?.gender,
          }),
        });

        // Switch to camera tab
        setActiveFolder('camera');

        let requestIdIndex = 0;
        const newTokens = (Number(authState.user?.user.tokens) || 0) - pack.photoCount;

        pack.prompts.forEach((prompt) => {
          // Get the requestId for this prompt
          const requestId = response.requestIds[requestIdIndex];

          for (let i = 0; i < prompt.count; i++) {
            const tempImage = {
              imageId: Math.random().toString(36).substring(7),
              requestId: requestId,
              userId: authState.user?.user.id || '',
              modelId: selectedModel?.modelId || '',
              imageLink: '',
              isLoading: true,
              description: '',
              modelName: selectedModel?.modelName || '',
              prompt: prompt.prompt,
              negativePrompt: '',
              seed: '',
              width: 512,
              height: 768,
              generateTimeMs: 0,
              createdAt: new Date().toISOString(),
              upscaled: false,
              isSaved: false,
              isDeleted: false,
              countIndex: i,
            };

            setCameraImages((prev) => [tempImage, ...prev]);
          }

          // Move to the next requestId after processing all images for this prompt
          if (requestIdIndex < response.requestIds.length - 1) {
            requestIdIndex++;
          }
        });

        // Update user tokens
        setAuthState((prevAuthState) => ({
          ...prevAuthState,
          user: prevAuthState.user
            ? {
                ...prevAuthState.user,
                user: {
                  ...prevAuthState.user.user,
                  tokens: newTokens.toString(),
                },
              }
            : null,
        }));
      },
      () => {},
      selectedModel?.imageUrl
    );
  };

  return (
    <a
      href={!clickEvent ? pack.href : undefined}
      onClick={clickEvent ? handleClick : undefined}
      className="div_template"
    >
      <div className="div_template_samples">
        {pack.imageUrls.map((img, index) => (
          <img
            key={index}
            loading="lazy"
            src={img}
            width={150}
            alt={`${pack.name} sample ${index + 1}`}
          />
        ))}
      </div>
      <div className="div_template_name">
        {pack.name}
        {!!pack.isNew && (
          <>
            &nbsp;<span className="badge">new</span>
          </>
        )}
      </div>
      <div className="div_template_description">{pack.description}</div>
      <div className="div_template_count">{pack.photoCount} photos</div>
      <br />
      <div className="div_times_used_count">{pack.usedCount}x ran this week</div>
    </a>
  );
}
