import { useState } from 'react';
import { stripePromise } from '../../../App';
import '../../../styles/Pricing.css';
import LoadingSpinner from '../../Dashboard/MainContent/LoadingSpinner';
import { useAuthContext } from '../../AuthContext';
import { useSnackbar } from '../../SnackbarContext';
import { Pricing } from '../../AppContext';

interface PricingPlanProps {
  plan: Pricing;
  isYearly: boolean;
  isPopular: boolean;
  switchPlan: () => void;
  manageSubscription?: boolean;
  homePage?: boolean;
}

export default function PricingPlan({
  plan,
  isYearly,
  isPopular,
  switchPlan,
  manageSubscription = false,
  homePage = false,
}: PricingPlanProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { authenticatedFetch } = useAuthContext();
  const { showSnackbar } = useSnackbar();
  const {
    planId,
    planName,
    description,
    monthlyPrice,
    yearlyPrice,
    stripePriceIdMonthly,
    stripePriceIdYearly,
  } = plan;

  const handleSubscribe = async () => {
    const stripe = await stripePromise;
    if (!stripe) return;
    setIsLoading(true);

    try {
      const response = await authenticatedFetch('/payment/createCheckoutSession', {
        method: 'POST',
        body: JSON.stringify({
          priceId: isYearly ? stripePriceIdYearly : stripePriceIdMonthly,
          planName: planName,
          subscriptionId: planId,
          isMonthly: !isYearly,
        }),
      });
      const { sessionId } = response;

      const { error } = await stripe.redirectToCheckout({ sessionId });
      if (error) {
        console.error('Stripe redirect error:', error);
        showSnackbar('Stripe error occurred :(', 'error');
      }
    } catch (error) {
      console.error('Create checkout session error:', error);
      showSnackbar('Stripe error occurred :(', 'error');
    }
  };

  const handleManageSubscription = async () => {
    setIsLoading(true);
    const response = await authenticatedFetch('/payment/createCustomerPortalSession', {
      method: 'GET',
      queryParams: {
        manageSubscription: true,
      },
    });
    const { url } = response;
    window.location.href = url;
  };

  return (
    <div
      className={`pricing-plan shaded_border ${isYearly ? 'yearly' : 'monthly'} ${isPopular ? 'popular' : ''}`}
      style={{ display: 'block' }}
    >
      {isPopular && (
        <div className="pricing-badge-container">
          <div className="pricing-badge">
            <span>Most popular</span>
          </div>
        </div>
      )}
      <h2>{planName}</h2>
      <p className="description"></p>
      <p className="price">
        <span className="number">
          ${isYearly ? Math.round(yearlyPrice / 12) : Math.round(monthlyPrice)}
        </span>
        <span>
          {isYearly && (
            <>
              6+ months free
              <br />
              billed yearly ${yearlyPrice}
              <br />
            </>
          )}
          <strong>per month</strong>
        </span>
      </p>
      <a
        tabIndex={-1}
        onClick={
          homePage ? undefined : manageSubscription ? handleManageSubscription : handleSubscribe
        }
        href={homePage ? '#' : undefined}
      >
        <button className={`subscribe ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>
          {isLoading ? (
            <div style={{ marginLeft: '40%' }}>
              <LoadingSpinner size={30} />
            </div>
          ) : manageSubscription ? (
            'Upgrade →'
          ) : (
            'Subscribe →'
          )}
        </button>
      </a>
      <a onClick={() => switchPlan()}>
        <p
          className={
            isYearly ? 'action-switch-to-monthly-billing' : 'action-switch-to-annual-billing'
          }
        >
          {isYearly ? 'View monthly billing ↗' : 'Save with yearly (6+ months free) ↗'}
        </p>
      </a>
      <ul className="features">
        {description.map((feature: { text: string; strong: boolean }, index: number) => (
          <li key={index} className={feature.strong ? 'strong' : ''}>
            <div className="check"></div>
            <span>{feature.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
}
