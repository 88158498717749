export const pages = [
  {
    id: 16,
    title: '🔥 Tinder Photos By AI',
    href: '/tinder',
    description: (
      <h3>
        Take photos with different poses, playful expressions, and vibrant colors to make your
        dating profile stand out on apps like Tinder. First you create an AI model of yourself, and
        then you can take AI photos with it! <br />
        <br />
        Save time and money and do an AI photo shoot from your laptop or phone instead of hiring an
        expensive photographer. See more <a href="#packs">photo packs</a> in different poses, places
        and styles.{' '}
      </h3>
    ),
    buttonText: 'Start your Tinder shoot now →',
  },
  {
    id: 13,
    title: '❤️‍🔥 AI Dating Photos',
    href: '/ai-dating-photos',
    description: (
      <h3>
        Use AI to generate better dating photos for Tinder, Bumble and Hinge. Get more matches and
        make your dating profile stand out on dating apps. Try different poses, clothes and places
        to make yourself look best!. First you create an AI model of yourself, and then you can take
        AI photos with it! <br />
        <br />
        Save time and money and do an AI photo shoot from your laptop or phone instead of hiring an
        expensive photographer. See more <a href="#packs">photo packs</a> in different poses, places
        and styles.{' '}
      </h3>
    ),
    buttonText: 'Start your AI Dating shoot now →',
  },
  {
    id: 12,
    title: '📸 Instagram Photos By AI',
    href: '/instagram',
    description: (
      <h3>
        Take engaging and visually stunning photos that showcase your personality as an Instagram
        influencer. Boost your confidence, likes and followers with captivating images that reflect
        your unique style and charisma. First you create an AI model of yourself, and then you can
        take AI photos with it! <br />
        <br />
        Save time and money and do an AI photo shoot from your laptop or phone instead of hiring an
        expensive photographer. See more <a href="#packs">photo packs</a> in different poses, places
        and styles.{' '}
      </h3>
    ),
    buttonText: 'Start your Avatar AI™ shoot now →',
  },
  {
    id: 17,
    title: '📸 YouTube Thumbnail Reaction Face Generator Photos',
    href: '/youtube-thumbnail-reaction-face',
    description: (
      <h3>
        Use AI to generate YouTube reaction faces for your thumbnails to increase clicks on your
        video. You will get photos of yourself with reaction faces like shocked, angry, happy and
        many more. First you create an AI model of yourself, and then you can take AI photos with
        it! <br />
        <br />
        Save time and money and do an AI photo shoot from your laptop or phone instead of hiring an
        expensive photographer. See more <a href="#packs">photo packs</a> in different poses, places
        and styles.{' '}
      </h3>
    ),
    buttonText: 'Start your Swimsuit shoot now →',
  },
  {
    id: 19,
    title: '🎲 A Night In Las Vegas Photos By AI',
    href: '/las-vegas',
    description: (
      <h3>
        Experience a thrilling night in Las Vegas. From dazzling casinos and the vibrant Las Vegas
        Strip to lively dive bars and night clubs, capture the essence of this iconic nightlife
        destination. First you create an AI model of yourself, and then you can take AI photos with
        it! <br />
        <br />
        Save time and money and do an AI photo shoot from your laptop or phone instead of hiring an
        expensive photographer. See more <a href="#packs">photo packs</a> in different poses, places
        and styles.{' '}
      </h3>
    ),
    buttonText: 'Start your Nightlife shoot now →',
  },
  {
    id: 20,
    title: '🎒 Digital Nomad Photos By AI',
    href: '/digital-nomad',
    description: (
      <h3>
        Transform yourself into a digital nomad, living and working remotely from anywhere in the
        world. This official pack by Nomads.com™, captures the essence of the nomadic lifestyle,
        featuring dynamic locations, workspaces, and the freedom of working on your own terms while
        exploring the globe. First you create an AI model of yourself, and then you can take AI
        photos with it! <br />
        <br />
        Save time and money and do an AI photo shoot from your laptop or phone instead of hiring an
        expensive photographer. See more <a href="#packs">photo packs</a> in different poses, places
        and styles.{' '}
      </h3>
    ),
    buttonText: 'Start your Naughty Halloween shoot now →',
  },
  {
    id: 21,
    title: '🌎 Travel Photos By AI',
    href: '/travel',
    description: (
      <h3>
        Travel the world and capture stunning photos from Paris to Tokyo. Showcase your global
        adventures with vibrant images from iconic cities and diverse cultures. First you create an
        AI model of yourself, and then you can take AI photos with it! <br />
        <br />
        Save time and money and do an AI photo shoot from your laptop or phone instead of hiring an
        expensive photographer. See more <a href="#packs">photo packs</a> in different poses, places
        and styles.{' '}
      </h3>
    ),
    buttonText: 'Start your Fitness shoot now →',
  },
  {
    id: 23,
    title: '💋 Boudoir Photos By AI',
    href: '/boudoir',
    description: (
      <h3>
        Enhance your allure with a stunning selection of sensual lingerie and elegant attire in this
        captivating boudoir shoot. These intimate sessions are not only empowering but also healing,
        helping boost your confidence and celebrate your beauty in a personal, transformative way.
        First you create an AI model of yourself, and then you can take AI photos with it! <br />
        <br />
        Save time and money and do an AI photo shoot from your laptop or phone instead of hiring an
        expensive photographer. See more <a href="#packs">photo packs</a> in different poses, places
        and styles.{' '}
      </h3>
    ),
    buttonText: 'Start your AI Girlfriend shoot now →',
  },
  {
    id: 24,
    title: '🐴 Old Money Photos By AI',
    href: '/old-money',
    description: (
      <h3>
        Transform yourself into the old money aesthetic with elegant preppy styles, reminiscent of
        Gossip Girl mixed with Ivy League charm. Capture the look while playing tennis at the
        country club, showcasing timeless sophistication and class. First you create an AI model of
        yourself, and then you can take AI photos with it! <br />
        <br />
        Save time and money and do an AI photo shoot from your laptop or phone instead of hiring an
        expensive photographer. See more <a href="#packs">photo packs</a> in different poses, places
        and styles.{' '}
      </h3>
    ),
    buttonText: 'Start your Cyberpunk shoot now →',
  },
  {
    id: 25,
    title: '📸 Polaroid Photos By AI',
    href: '/polaroid',
    description: (
      <h3>
        Polaroid photographs with a classic analog camera for a vintage, timeless look. Capture
        moments with an authentic, nostalgic feel that stands out in a digital age. First you create
        an AI model of yourself, and then you can take AI photos with it!
        <br />
        <div className="benefit">
          ✏️ <a href="#selfies">Upload your selfies</a> and create your own{' '}
          <a href="#model">AI model</a>
        </div>
        <div className="benefit">
          📸 <a href="#prompt">Turn your words into AI photos</a> with Text-to-Image prompts
        </div>
        <div className="benefit">
          ❤️ <a href="#packs">Get photo packs</a> like AI Yearbook, Old Money and Naughty Halloween
        </div>{' '}
      </h3>
    ),
    buttonText: 'Create your AI model +',
  },
  {
    id: 27,
    title: '📸 Professional Headshots Photos By AI',
    href: '/headshots',
    description: (
      <h3>
        Get a professional look with professional headshots you can use on your LinkedIn. Stand out
        from the competition, increase your visibility, attract more job offers by making a strong
        first impression. First you create an AI model of yourself, and then you can take AI photos
        with it!
        <br />
        <div className="benefit">
          ✏️ <a href="#selfies">Upload your selfies</a> and create your own{' '}
          <a href="#model">AI model</a>
        </div>
        <div className="benefit">
          📸 <a href="#prompt">Turn your words into AI photos</a> with Text-to-Image prompts
        </div>
        <div className="benefit">
          ❤️ <a href="#packs">Get photo packs</a> like AI Yearbook, Old Money and Naughty Halloween
        </div>{' '}
      </h3>
    ),
    buttonText: 'Create your AI model +',
  },
];
