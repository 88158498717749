import '../../../styles/Features.css';

export default function CreateFeature() {
  return (
    <div>
      <div className="feature">
        <div className="feature-left">
          <h2 id="model">Create your own AI model</h2>
          <p>
            Create photography with artificial intelligence by creating your own AI model. Upload a
            set of just 10 to 20 photos in a diverse range of places, settings, and times. By
            inputting these images into your model, you're teaching it to recognize and replicate
            it.
            <br />
            <br />
            You only need to train your model once which takes about 10 minutes. Then you can take
            infinite photos with it.
          </p>
        </div>
        <div className="feature-right feature-3d">
          <div style={{ position: 'relative', paddingTop: '62.28373702422145%' }}>
            <video
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
              }}
              playsInline
              controls
            >
              <source src="videos/createModel.mp4" type="video/mp4" />
              {/* Fallback text if video fails to load */}
              <p>Your browser doesn't support this video</p>
            </video>
          </div>
        </div>
      </div>
      <div style={{ clear: 'both' }} />
      <br />
      <br />
      <br />
    </div>
  );
}
