import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth } from '../../firebase';
import { UserType } from '../Dashboard/DashboardContext';
import { useSnackbar } from '../SnackbarContext';

interface EmailLinkSignInProps {
  user: UserType | null;
  userToken: string | null;
}

export default function useEmailLinkSignIn({ user, userToken }: EmailLinkSignInProps) {
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    async function handleEmailLinkSignIn() {
      if (!isSignInWithEmailLink(auth, window.location.href)) return;

      try {
        const email = window.localStorage.getItem('emailForSignIn');
        if (!email) throw new Error('No email found for sign-in');

        await signInWithEmailLink(auth, email, window.location.href);
        window.localStorage.removeItem('emailForSignIn');

        // The auth state will update automatically, triggering a re-render
      } catch {
        showSnackbar('Error signing in.', 'error');
        navigate('/');
      }
    }

    handleEmailLinkSignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  useEffect(() => {
    if (user && userToken) {
      if (location.pathname === '/') {
        navigate(user.user.subscription ? '/dashboard' : '/pricing');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userToken]);
}
