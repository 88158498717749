import { useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import { pages } from './data/pages';
import NotFound from './pages/NotFound';
import PricingPage from './pages/Pricing';
import Dashboard from './pages/Dashboard';
import useEmailLinkSignIn from './components/AppContent/EmailLinkSignIn';
import ProtectedRoute from './components/AppContent/ProtectedRoute';
import { useDashboard } from './components/Dashboard/DashboardContext';
import { useAuthContext } from './components/AuthContext';
import { useApp } from './components/AppContext';
import Processing from './pages/Processing';
import Billing from './pages/Billing';
import LegalPage from './pages/LegalPage';
import customFetch from './utils/FetchHook';

export default function AppContent() {
  const { authState } = useDashboard();
  const { user, userToken, isAuthenticated, isLoading } = authState;
  const { authenticatedFetch } = useAuthContext();
  const { setPhotoPacks, setPricing } = useApp();
  const location = useLocation();
  const dataCall = useRef(false);
  useEmailLinkSignIn({ user, userToken });

  const getHtmlClass = () => {
    if (location.pathname === '/dashboard') {
      return 'dark';
    } else {
      return 'dark guest';
    }
  };

  useEffect(() => {
    document.documentElement.className = getHtmlClass();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (dataCall.current) return;
    dataCall.current = true;

    const grabPhotoPacks = async () => {
      // Grab photo packs
      const photoPacks = await authenticatedFetch('/image/getPhotoPacks/', {
        method: 'GET',
      });
      setPhotoPacks(photoPacks.data);

      // Grab pricing
      const pricing = await customFetch({
        endpoint: '/user/getPricing/',
        options: { method: 'GET' },
      });
      setPricing(pricing);
    };

    grabPhotoPacks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      {pages.map((page) => (
        <Route
          key={page.href}
          path={page.href}
          element={<Home id={page.id} title={page.title} description={page.description} />}
        />
      ))}
      <Route
        path="/billing"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated} isLoading={isLoading}>
            <Billing title={'Billing | DreamSnapAI'} />
          </ProtectedRoute>
        }
      />
      <Route path="/legal" element={<LegalPage />} />
      <Route
        path="/processing/:sessionId"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated} isLoading={isLoading}>
            <Processing title={'Processing Payment | DreamSnapAI'} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pricing"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated} isLoading={isLoading}>
            <PricingPage title={'AI Photo Pricing | DreamSnapAI'} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated} isLoading={isLoading}>
            <Dashboard title="DreamSnapAI: 🔥 Fire your photographer" />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<NotFound title={'Page not found'} />} />
    </Routes>
  );
}
