import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from './styles/theme';
import './styles/global.css';
import AppContent from './AppContent';
import { TooltipProvider } from './components/Dashboard/Tooltip/TooltipContext';
import { DashboardProvider } from './components/Dashboard/DashboardContext';
import { SnackbarProvider } from './components/SnackbarContext';
import SnackbarComponent from './components/Snackbar';
import { ModalAlertProvider } from './components/ModalAlertContext';
import { AuthProvider } from './components/AuthContext';
import { AppProvider } from './components/AppContext';
import { loadStripe } from '@stripe/stripe-js';

export const stripePromise = loadStripe(
  process.env.NODE_ENV === 'development'
    ? 'pk_test_51PWJxJI0JBtbSFRbLxPmMH20Z2VILBPFmFTfxGGZgRSgtLM6SrbC0WM8h9NE8y9Ebx8XdhHVKzWFCx22SaV7JAxE005nKDM9BU'
    : 'pk_live_51PWJxJI0JBtbSFRb6K5SQICEP7GT9hP6MJZ4XhHQZEfKPohgl2pjcq733zmgJm4wcAnjEHtHp42zUXHH3vKTXbbY00bZAmJY7N'
);

export default function App() {
  return (
    <DashboardProvider>
      <AppProvider>
        <AuthProvider>
          <ModalAlertProvider>
            <SnackbarProvider>
              <TooltipProvider>
                <ThemeProvider theme={theme}>
                  <Router>
                    <AppContent />
                    <SnackbarComponent />
                  </Router>
                </ThemeProvider>
              </TooltipProvider>
            </SnackbarProvider>
          </ModalAlertProvider>
        </AuthProvider>
      </AppProvider>
    </DashboardProvider>
  );
}
