import '../../../styles/Features.css';

export default function PhotorealisticFeature() {
  return (
    <div>
      <div className="feature">
        <div className="feature-left">
          <h2 id="prompt">Design photorealistic scenes</h2>
          <p>
            Design any scene you wish, from commonplace to rare, stunning instances. Imagine the
            ability to create a photograph of a sunlit Parisian cafe in the 1920s, or a moonlit
            beach in Bali with just the right clothes, all from the comfort of your living room.{' '}
            <br />
            <br />
            Simply describe your desired scene and watch as the model generates a highly realistic
            photo that aligns with your vision. Taking photos is fast: right now it takes about 53
            seconds per photo.
          </p>
        </div>
        <div className="feature-right feature-3d">
          <div style={{ position: 'relative', paddingTop: '62.28373702422145%' }}>
            <video
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
              }}
              playsInline
              controls
            >
              <source src="videos/takePhoto.mp4" type="video/mp4" />
              {/* Fallback text if video fails to load */}
              <p>Your browser doesn't support this video</p>
            </video>
          </div>
        </div>
      </div>
      <div style={{ clear: 'both' }} />
      <br />
      <br />
      <br />
    </div>
  );
}
