import '../../styles/Dashboard/TopFolderSelector.css';

interface TopFolderSelectorProps {
  activeFolder: string;
  setActiveFolder: (folder: string) => void;
}

export default function TopFolderSelector({
  activeFolder,
  setActiveFolder,
}: TopFolderSelectorProps) {
  const toggleTab = (tab: string) => {
    setActiveFolder(tab);
  };

  return (
    <div className="top_folder_selector">
      <h3
        data-folder="templates"
        className={`${activeFolder === 'templates' ? 'active' : ''}`}
        onClick={() => toggleTab('templates')}
      >
        Packs
      </h3>

      <h3
        data-folder="examples"
        className={`${activeFolder === 'examples' ? 'active' : ''}`}
        onClick={() => toggleTab('examples')}
      >
        Prompts
      </h3>

      <h3
        data-folder="camera"
        className={`${activeFolder === 'camera' ? 'active' : ''}`}
        onClick={() => toggleTab('camera')}
      >
        <span
          className="icon"
          style={{
            fontSize: '25px',
            lineHeight: '10px',
            verticalAlign: 'top',
            marginTop: '5px',
            display: 'inline-block',
          }}
        >
          ֎
        </span>{' '}
        Camera
      </h3>

      <h3
        data-folder="saved"
        className={`${activeFolder === 'saved' ? 'active' : ''}`}
        onClick={() => toggleTab('saved')}
      >
        Saved
      </h3>
    </div>
  );
}
