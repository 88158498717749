import PackItem from './PackItem';
import '../../../styles/Packs.css';
import { useApp } from '../../AppContext';

export default function Packs() {
  const { photoPacks } = useApp();

  return (
    <div id="packs" className="templates">
      <h2 style={{ textAlign: 'center' }}>🔥 New packs just dropped</h2>
      <p style={{ textAlign: 'center' }}>
        All packs are included in your membership! You can try as many as you want.
      </p>
      <br />
      <br />
      {photoPacks.map((pack) => (
        <PackItem key={pack.id} pack={pack} />
      ))}
    </div>
  );
}
