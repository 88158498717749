import FAQItem from './FAQItem';
import '../../../styles/FAQ.css';
import { useState } from 'react';

interface FAQProps {
  faqItems: FAQItem[];
}

export interface FAQItem {
  question: string;
  answer: string;
  isExpanded?: boolean;
}

export default function FAQ({ faqItems }: FAQProps) {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div>
      <div className="faq" id="faq">
        <h2>Frequently asked questions</h2>
        <a href="#">
          <img
            width="586"
            height="879"
            className="faq-img"
            loading="lazy"
            src="/faq/faq.jfif"
            alt="FAQ illustration"
          />
        </a>

        <div className="faq-right">
          {faqItems.map((item, index) => (
            <FAQItem
              key={index}
              {...item}
              isExpanded={expandedIndex === index}
              onClick={() => toggleFAQ(index)}
            />
          ))}
        </div>
      </div>
      <div style={{ clear: 'both' }} />
      <br />
      <br />
      <br />
    </div>
  );
}
