import DocumentTitle from '../utils/DocumentTitle';

interface NotFoundProps {
  title: string;
}

export default function NotFound({ title }: NotFoundProps) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <DocumentTitle title={title} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1
          style={{
            display: 'inline-block',
            margin: '0 20px 0 0',
            paddingRight: '23px',
            fontSize: '24px',
            fontWeight: '500',
            borderRight: '1px solid #e5e7eb',
          }}
        >
          404
        </h1>
        <h2
          style={{
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '28px',
          }}
        >
          This page could not be found.
        </h2>
      </div>
    </div>
  );
}
