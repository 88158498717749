import '../../styles/Dashboard/TakeAPhotoRow.css';
import { useTooltip } from './Tooltip/TooltipContext';
import { useDashboard } from './DashboardContext';
import GenericButton from './GenericButton';
import { useAuthContext } from '../AuthContext';
import { useState } from 'react';

export default function TakeAPhotoRow() {
  const { showTooltip, hideTooltip } = useTooltip();
  const { photoPrompt, setPhotoPrompt, setTakeAPhotoExpanded, takeAPhotoExpanded } = useDashboard();
  const [description, setDescription] = useState('');
  const [fancifyLoading, setFancifyLoading] = useState(false);
  const { authenticatedFetch } = useAuthContext();

  const toggleTab = () => {
    setTakeAPhotoExpanded(!takeAPhotoExpanded);
  };

  const handleInfoMouseEnter = (event: React.MouseEvent<HTMLSpanElement>) => {
    const target = event.currentTarget;
    const rect = target.getBoundingClientRect();
    const leftPx = rect.left;
    const topPx = rect.top;

    const tooltipHeading = target.parentElement?.textContent?.trim();
    let tooltipText = target.dataset.tooltip;
    tooltipText = tooltipText?.replace(/\*linebreak\*/g, '<br/>');
    const tooltipImage = target.dataset.tooltipImage;

    const content = `<b style="margin-bottom:7px;display:inline-block;">${tooltipHeading}</b><br/>${tooltipText}`;

    showTooltip({
      content,
      image: tooltipImage,
      position: { left: leftPx, top: topPx },
    });
  };

  const handleFancifyClick = async () => {
    setFancifyLoading(true);
    const response = await authenticatedFetch('/inference/extendPrompt', {
      method: 'GET',
      queryParams: {
        promptDescription: description,
      },
    });
    setPhotoPrompt({ ...photoPrompt, prompt: response.msg });
    setFancifyLoading(false);
  };

  const handleInfoMouseLeave = () => {
    hideTooltip();
  };

  return (
    <div className="prompt_mode">
      <h3
        className={`h3_input expands_next_div ${takeAPhotoExpanded ? 'expanded' : ''}`}
        onClick={toggleTab}
      >
        Take a photo
      </h3>

      <div className={`expandable_div ${takeAPhotoExpanded ? 'expanded' : ''}`}>
        <p>
          <span className="label">
            Prompt Description
            <span
              className="info"
              data-tooltip="This is the description of your prompt. Once you have finished, click Fancify and it will generate a full blown prompt for you."
              onMouseEnter={handleInfoMouseEnter}
              onMouseLeave={handleInfoMouseLeave}
            ></span>
          </span>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="manual_description"
            placeholder="(*OPTIONAL*)This is a short description of what you want your prompt to be. Press Fancify to generate a full prompt."
          ></textarea>
        </p>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em' }}>
          <GenericButton text="✨ Fancify" onClick={handleFancifyClick} loading={fancifyLoading} />
        </div>
        <p>
          <span className="label">
            What do you want to create? (Prompt)
            <span
              className="info"
              data-tooltip='The prompt describes what you want to create in your photo. You can use a single word or a full sentence. Write "{model}" to reference your model, like "{model} wearing floral silk dress in cocktail bar with neon lights"'
              onMouseEnter={handleInfoMouseEnter}
              onMouseLeave={handleInfoMouseLeave}
            ></span>
          </span>
          <textarea
            value={photoPrompt.prompt}
            onChange={(e) => setPhotoPrompt({ ...photoPrompt, prompt: e.target.value })}
            className="manual_prompt show-outline-if-active active"
            placeholder='Write "{model}" to reference your model like "{model} wearing floral silk dress in cocktail bar with neon lights". Text also works like "{model} holding sign that says Hello World"'
          ></textarea>
        </p>
        <p className="subline" style={{ marginTop: '-14px', marginBottom: '21px' }}>
          Make sure to write <strong style={{ color: '#fff' }}>{'{model}'}</strong> above, or if you
          want create a photo without your model, do not write it.
        </p>
        <p></p>
      </div>
    </div>
  );
}
