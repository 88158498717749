import '../../../styles/HeroQuotes.css';

export default function HeroFooterQuotes() {
  const quoteJSX = (text: string, index: number) => {
    return (
      <div className={`hero_footer_quote quote_${index}`}>
        <p>{text}</p>
      </div>
    );
  };

  return (
    <div className="hero_footer_quotes">
      {quoteJSX(
        '"DreamSnapAI revolutionizes the industry by offering on-demand image generation, powered by advanced computational resources."',
        1
      )}
      {quoteJSX(
        '"With DreamSnapAI, content creators can produce professional-quality visuals instantly, eliminating the need for traditional photoshoots and dramatically reducing costs."',
        2
      )}
      {quoteJSX(
        '"DreamSnapAI\'s groundbreaking technology allows for the creation of infinite personalized portraits, complete with an extensive virtual wardrobe at your fingertips."',
        3
      )}
    </div>
  );
}
