import { useCallback, useEffect, useRef, useState } from 'react';
import { useDashboard } from '../DashboardContext';
import { ExampleImage } from './ExampleImage';
import { useAuthContext } from '../../AuthContext';
import { useSnackbar } from '../../SnackbarContext';

export default function ExamplesFolder() {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { promptExamples, setPromptExamples, authState, seed } = useDashboard();
  const { showSnackbar } = useSnackbar();
  const { authenticatedFetch } = useAuthContext();

  const observer = useRef<IntersectionObserver | null>(null);
  const lastImageElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const grabPrompts = useCallback(
    async (page: number) => {
      if (page === 1) return;
      try {
        setLoading(true);
        // Grab prompt examples
        const response = await authenticatedFetch('/image/getPromptExamples/', {
          method: 'GET',
          queryParams: {
            seed: seed,
            page: page,
          },
        });

        if (response.data.length === 0) {
          setHasMore(false);
        } else {
          setPromptExamples((prev) => [...prev, ...response.data]);
        }
      } catch {
        showSnackbar('Error fetching images', 'error');
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authState.userToken]
  );

  useEffect(() => {
    if (promptExamples.length < 20) return;

    grabPrompts(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, grabPrompts]);

  return (
    <div
      className="div_output_images examples"
      style={{ opacity: 1, display: 'block', paddingBottom: '200px' }}
    >
      {promptExamples.map((example, index) => (
        <ExampleImage
          key={example.promptId}
          example={example}
          ref={index === promptExamples.length - 1 ? lastImageElementRef : null}
        />
      ))}
    </div>
  );
}
