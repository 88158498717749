export default function LegalPage() {
  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: 'white' }}>
      <iframe
        src="/legal.html"
        title="Legal Document"
        style={{ width: '100%', height: '100%', border: 'none' }}
      />
    </div>
  );
}
