import { useEffect } from 'react';
import styles from '../../../styles/Netflix.module.css';
import customFetch from '../../../utils/FetchHook';
import { useApp } from '../../AppContext';

interface NetflixProps {
  id?: number;
}

export default function Netflix({ id }: NetflixProps) {
  const { currentImages, setCurrentImages } = useApp();

  //Fisher-Yates shuffle algorithm
  const shuffleArray = (array: string[]) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // Grab images urls
  useEffect(() => {
    const grabImages = async () => {
      if (currentImages.length > 0) {
        return;
      }
      const response = await customFetch({
        endpoint: '/image/getPhotoPackImages',
        options: {
          method: 'GET',
          queryParams: {
            photoPackId: id || '',
          },
        },
      });
      setCurrentImages(response.data);
    };

    grabImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Precompute shuffled images once
  const rows = Array(7)
    .fill(null)
    .map(() => shuffleArray([...currentImages]).slice(0, 20));

  return (
    <div className={styles.netflixContainer}>
      <div className={styles.netflixGradient} />
      <div className={styles.netflixContainerPerspective}>
        <div className={styles.netflixContainerBackground}>
          {rows.map((rowImages, rowIndex) => (
            <div key={rowIndex} className={styles.netflixBox}>
              {rowImages.map((img, index) => (
                <div
                  key={`${rowIndex}-${index}`}
                  className={styles.netflixThumbnail}
                  style={{
                    backgroundImage: `url(https://imagetransform.dreamsnapai.com/${img}?format=auto,fit=cover,width=250,height=375,quality=50)`,
                  }}
                />
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
