interface customFetchProps {
  endpoint: string;
  options?: RequestInit & {
    queryParams?: Record<string, string | number | boolean>;
  };
  token?: string;
}

let baseURL = '';

if (process.env.NODE_ENV === 'development') {
  baseURL = 'https://api_dev.dreamsnapai.com';
} else {
  baseURL = 'https://api.dreamsnapai.com';
}

const customFetch = async ({ endpoint, options = {}, token }: customFetchProps) => {
  let url = `${baseURL}${endpoint}`;

  // Handle query parameters
  if (options.queryParams) {
    const queryString = new URLSearchParams(
      Object.entries(options.queryParams).map(([key, value]) => [key, value.toString()])
    ).toString();
    url += `?${queryString}`;
  }

  // Determine the content type
  let contentType = 'application/json';
  if (options.body instanceof FormData) {
    // If it's FormData, don't set Content-Type, let the browser handle it
    contentType = '';
  }

  // Add default headers
  const headers: Record<string, string> = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  // Only add Content-Type if it's not empty
  if (contentType) {
    headers['Content-Type'] = contentType;
  }

  const { body, ...fetchOptions } = options;
  const config: RequestInit = {
    ...fetchOptions,
    headers,
  };

  // Handle body based on content type
  if (body) {
    if (body instanceof FormData) {
      config.body = body;
    } else if (typeof body === 'object') {
      config.body = JSON.stringify(body);
    } else {
      config.body = body;
    }
  }

  try {
    const response = await fetch(url, config);

    if (!response.ok) {
      if (response.status === 401) {
        throw new Error('Invalid token');
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export default customFetch;
