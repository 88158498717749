import { forwardRef, useEffect, useState } from 'react';
import { PromptExample, useDashboard } from '../DashboardContext';
import { useSnackbar } from '../../SnackbarContext';

interface ExampleImageProps {
  example: PromptExample;
}

export const ExampleImage = forwardRef<HTMLDivElement, ExampleImageProps>(({ example }, ref) => {
  const { setPhotoPrompt } = useDashboard();
  const [isMobile, setIsMobile] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleCopyPrompt = () => {
    setPhotoPrompt((prev) => ({ ...prev, prompt: example.prompt }));
    showSnackbar('Prompt copied to input in take a photo.', 'success');
  };

  const handleImageClick = () => {
    if (isMobile) {
      setIsHovering(!isHovering);
    } else {
      handleCopyPrompt();
    }
  };

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <div
      ref={ref}
      className={`div_output_image example  finished racy example ${isHovering ? 'active' : ''}`}
      onClick={handleImageClick}
      onMouseEnter={() => !isMobile && setIsHovering(true)}
      onMouseLeave={() => !isMobile && setIsHovering(false)}
    >
      <div className="div_output_image_buttons" onClick={handleCopyPrompt}>
        <div className="action-copy-prompt">
          <span></span>
          <br />
          Copy prompt
        </div>
      </div>
      <div className="div_output_image_meta">{example.prompt}</div>
      <div className="div_output_image_container">
        <img width="512" height="768" className="output_image" src={example.imageLink} />
      </div>
    </div>
  );
});
