import { useEffect, useState } from 'react';
import '../styles/Processing.css';
import { useAuthContext } from '../components/AuthContext';
import { useDashboard } from '../components/Dashboard/DashboardContext';
import DocumentTitle from '../utils/DocumentTitle';

interface BillingProps {
  title: string;
}

export default function Billing({ title }: BillingProps) {
  const [dots, setDots] = useState('');
  const { authState } = useDashboard();
  const { authenticatedFetch } = useAuthContext();

  useEffect(() => {
    const grabData = async () => {
      if (!authState.userToken) return;
      const response = await authenticatedFetch('/payment/createCustomerPortalSession');
      const { url } = response;
      window.location.href = url;
    };

    grabData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.userToken]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prev) => (prev.length >= 3 ? '' : prev + '.'));
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="processing-container">
      <DocumentTitle title={title} />
      <div className={'processing-card'}>
        <h2 className="processing-heading animated-dots">Re-routing</h2>
        <p className="processing-paragraph">
          Please wait while we re-route you to Stripe's Billing page{dots}
        </p>
      </div>
    </div>
  );
}
