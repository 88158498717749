interface LoadingSpinnerProps {
  size?: number;
}

export default function LoadingSpinner({ size = 50 }: LoadingSpinnerProps) {
  const spinnerStyle: React.CSSProperties = {
    width: `${size}px`,
    height: `${size}px`,
  };

  return (
    <div style={spinnerStyle}>
      <svg className="loading_spinner_svg" viewBox="25 25 50 50">
        <circle
          className="loading_spinner_circle loading_spinner_circle--spinner"
          cx="50"
          cy="50"
          r="20"
          fill="none"
        />
      </svg>
    </div>
  );
}
