import '../../styles/Dashboard/GenericButton.css';
import LoadingSpinner from './MainContent/LoadingSpinner';

interface GenericButtonProps {
  text: string;
  onClick?: () => void;
  loading?: boolean;
}

export default function GenericButton({ text, onClick, loading }: GenericButtonProps) {
  return (
    <div>
      <div
        className={`button fancify`}
        onClick={!loading ? onClick : undefined}
        style={{ minWidth: '100px', justifyItems: 'center' }}
      >
        {loading ? <LoadingSpinner size={20} /> : <span className="button-label">{text}</span>}
      </div>
    </div>
  );
}
