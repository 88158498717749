import { useEffect } from 'react';
import '../../styles/Dashboard/SelectModelRow.css';
import DisplayModel from './Models/DisplayModel';
import { useDashboard } from './DashboardContext';

interface SelectModelRowProps {
  trainingTabRef: React.RefObject<HTMLDivElement>;
}

export default function SelectModelRow({ trainingTabRef }: SelectModelRowProps) {
  const {
    authState,
    selectedModel,
    setSelectedModel,
    setTrainingExpanded,
    selectModelExpanded,
    setSelectModelExpanded,
  } = useDashboard();

  const toggleTab = () => {
    setSelectModelExpanded(!selectModelExpanded);
  };

  const handleModelSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedModel(
      authState.user?.models.find((model) => event.target.value === model.modelName)
    );
  };

  const createNewModel = () => {
    setTrainingExpanded(true);
    trainingTabRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    // Check if there are any private models
    if (authState.user?.models.filter((model) => !model.isPublic).length === 0) {
      setSelectedModel(authState.user?.models.find((model) => !model.isLoading));
    } else {
      setSelectedModel(
        authState.user?.models.find((model) => !!model.isPublic === false && !model.isLoading)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <h3
        className={`h3_input expands_next_div ${selectModelExpanded ? 'expanded' : ''}`}
        onClick={toggleTab}
      >
        Select model{' '}
        <span
          className="select_your_model_current_model_thumbnail"
          style={{
            backgroundImage: `url(${authState.user?.models.find((model) => model.modelId === selectedModel?.modelId)?.imageUrl})`,
            textAlign: 'center',
            alignContent: 'center',
          }}
        >
          {selectedModel === undefined ? '🎲' : ''}
        </span>
      </h3>
      <div className={`expandable_div basics ${selectModelExpanded ? 'expanded' : ''}`}>
        <p>
          <select
            className="model_id"
            name="model_id"
            style={{ marginBottom: '-15px', borderBottom: '0' }}
            value={selectedModel?.modelName}
            onChange={handleModelSelect}
          >
            <option value="create">Create new model</option>
            <option disabled></option>
            <option
              value={
                authState.user?.models.find((model) => model.modelId === selectedModel?.modelId)
                  ?.modelId
              }
            >
              {
                authState.user?.models.find((model) => model.modelId === selectedModel?.modelId)
                  ?.modelName
              }
            </option>
            <option disabled>————————</option>
            {authState.user?.models.map((model) => (
              <option key={model.modelId} value={model.modelName}>
                {model.modelName}
              </option>
            ))}
            <option value="create">Create new AI model</option>
          </select>
        </p>
        <div className="model_samples" style={{ marginLeft: '21px', marginRight: '21px' }}>
          <div
            className="model_sample action-create-new-model"
            style={{ background: '#2b2b2b' }}
            onClick={createNewModel}
          >
            <img
              className="model_sample_img"
              width="100"
              height="100"
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              style={{ width: '100%', display: 'block' }}
            />
            <div
              style={{
                position: 'absolute',
                fontSize: '36px',
                color: '#fff',
                transform: 'translateY(-50%) translateX(-50%)',
                left: '50%',
                top: '50%',
                marginTop: '0',
              }}
            >
              +
            </div>
          </div>

          {/* Random Model */}
          <div
            className={`model_sample ${selectedModel === undefined ? 'active' : ''}`}
            style={{ background: '#2b2b2b' }}
            onClick={() => setSelectedModel(undefined)}
          >
            <img
              className="model_sample_img"
              width="100"
              height="100"
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              style={{ width: '100%', display: 'block' }}
            />
            <div
              style={{
                position: 'absolute',
                fontSize: '36px',
                color: '#fff',
                transform: 'translateY(-50%) translateX(-50%)',
                left: '50%',
                top: '50%',
                marginTop: '0',
              }}
            >
              🎲
            </div>
          </div>

          {/* Private models */}
          {authState.user?.models
            .filter((item) => !item.isPublic)
            .map((model) => (
              <DisplayModel
                key={model.modelId}
                model={model}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
              />
            ))}

          <div className="divider">
            <span>Public models</span>
          </div>

          {/* Public models */}
          {authState.user?.models
            .filter((item) => item.isPublic)
            .map((model) => (
              <DisplayModel
                key={model.modelId}
                model={model}
                selectedModel={selectedModel}
                setSelectedModel={setSelectedModel}
              />
            ))}
        </div>
      </div>
    </div>
  );
}
