import '../../../styles/FAQ.css';

interface FAQItem {
  question: string;
  answer: string;
  isExpanded?: boolean;
  onClick: () => void;
}

export default function FAQItem({ question, answer, isExpanded = false, onClick }: FAQItem) {
  return (
    <div onClick={onClick}>
      <h4 className={isExpanded ? 'expanded' : ''}>{question}</h4>
      <p className={isExpanded ? 'expanded' : ''} dangerouslySetInnerHTML={{ __html: answer }} />
    </div>
  );
}
