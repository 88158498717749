import { initializeApp } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDMUxI8rjjAceQiu4MSPXT4DiM4-Uf7Q1I',
  authDomain: 'dreamsnapai.firebaseapp.com',
  projectId: 'dreamsnapai',
  storageBucket: 'dreamsnapai.appspot.com',
  messagingSenderId: '72535089339',
  appId: '1:72535089339:web:8983e5617a13448c41499e',
  measurementId: 'G-75JS5V0P30',
};

const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Set persistence immediately, but handle as a promise
setPersistence(auth, browserLocalPersistence).catch((error) => {
  console.error('Failed to set persistence:', error);
});
