import MainOutputImageExamples from './MainOutputImageExamples';
import '../../../styles/MainSection.css';

export default function Main() {
  return (
    <div>
      <div className="section main" tabIndex={-1}>
        <div className="landing-main-top-gradient" />
        <MainOutputImageExamples />
      </div>
    </div>
  );
}
