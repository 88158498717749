import '../../../styles/FeatureComparison.css';

export default function FeatureComparison() {
  return (
    <div className="feature comparisons">
      <h2 style={{ textAlign: 'center' }}>
        How does DreamSnapAI compare to other AI image generators?
      </h2>
      <p>
        With the same uploaded selfies, DreamSnapAI performs far better than competitors in
        photorealism and resemblance.
      </p>
      <br />

      <div className="comparison">
        <img
          loading="lazy"
          width="512"
          height="768"
          src="/comparison/midjourney.webp"
          alt="Midjourney 3 comparison"
        />

        <div className="label">
          Midjourney 3<br />
          <br />
          ❌ Low resemblance
          <br />
          ❌ Inconsistent character
          <br />
          ✅ Medium photorealism
          <br />
          ❌ Low resolution
          <br />
          ✅ Maintains ethnicity
          <br />
          ❌ Clear and sharp
          <br />
        </div>
      </div>

      <div className="comparison photo">
        <a href="">
          <img
            loading="lazy"
            className="action-view-image output_image"
            width="512"
            height="768"
            src="/comparison/dreamsnap.png"
            alt="DreamSnapAI comparison"
          />
        </a>
        <div className="label">
          <strong>DreamSnapAI</strong>
          <br />
          <br />
          ✅ High resemblance
          <br />
          ✅ Consistent character
          <br />
          ✅ High photorealism
          <br />
          ✅ High resolution
          <br />
          ✅ Maintains ethnicity
          <br />
          ✅ Clear and sharp
          <br />
        </div>
      </div>

      <div className="comparison">
        <img
          loading="lazy"
          width="512"
          height="768"
          src="/comparison/dalle.webp"
          alt="DALL-E 3 comparison"
        />
        <div className="label">
          DALL-E 3<br />
          <br />
          ❌ No resemblance
          <br />
          ❌ Inconsistent character
          <br />
          ❌ Low photorealism
          <br />
          ❌ No resolution
          <br />
          ✅ Maintains ethnicity
          <br />
          ❌ Clear and sharp
          <br />
        </div>
      </div>
    </div>
  );
}
