import '../../styles/Dashboard/Header.css';
import { useDashboard } from './DashboardContext';

interface LeftMenuNavProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  headerButtonRef: React.RefObject<HTMLDivElement>;
  setMenuPosition: (menuPosition: string) => void;
}

export default function DashHeader({
  isMenuOpen,
  setIsMenuOpen,
  headerButtonRef,
  setMenuPosition,
}: LeftMenuNavProps) {
  const { authState } = useDashboard();

  const toggleMenu = (position: string) => {
    setIsMenuOpen(!isMenuOpen);
    setMenuPosition(position);
  };

  return (
    <div ref={headerButtonRef} className="header">
      <div className="logo" onClick={() => toggleMenu('left')}>
        <a tabIndex={-1}>
          <h1 style={{ fontWeight: 300 }}>
            <div className="logo-box"></div>
            <span style={{ fontWeight: 'bold' }}>DreamSnap</span>
            AI
          </h1>
        </a>
      </div>

      <h2></h2>
      <div className="header-right">
        <div
          className="header-link photo-credits-left-container credits"
          onClick={() => toggleMenu('right')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="tabler-icon tabler-icon-bolt"
          >
            <path d="M13 3l0 7l6 0l-8 11l0 -7l-6 0l8 -11"></path>
          </svg>
          <span className="photoCreditsLeft">{authState.user?.user.tokens}</span>
        </div>
      </div>
      <div className="rainbow-line" />
    </div>
  );
}
