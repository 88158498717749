import { ReactNode } from 'react';
import HeroFooterQuotes from './HeroFooterQuotes';
import HeroWidthContainer from './HeroWidthContainer';
import Netflix from './Netflix';

interface HeroProps {
  id: number | undefined;
  title: string | undefined;
  description: ReactNode | undefined;
  showLogin: boolean;
}

export default function Hero({ id, title, description, showLogin }: HeroProps) {
  return (
    <div id="hero" className="hero">
      <svg viewBox="0 0 1440 120" className="wave">
        <path d="M1440,21.2101911 L1440,120 L0,120 L0,21.2101911 C120,35.0700637 240,42 360,42 C480,42 600,35.0700637 720,21.2101911 C808.32779,12.416393 874.573633,6.87702029 918.737528,4.59207306 C972.491685,1.8109458 1026.24584,0.420382166 1080,0.420382166 C1200,0.420382166 1320,7.35031847 1440,21.2101911 Z"></path>
      </svg>
      <HeroWidthContainer title={title} description={description} showLogin={showLogin} />
      <Netflix id={id} />
      {/* <HeroFooterLogos /> */}
      <HeroFooterQuotes />
      <div className="clear:both" />
    </div>
  );
}
