import { OutputImage } from './OutputImage';
import '../../../styles/Dashboard/OutputImageContainer.css';
import { ImageType, useDashboard } from '../DashboardContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSnackbar } from '../../SnackbarContext';
import { useAuthContext } from '../../AuthContext';

interface OutputImageContainerProps {
  images: ImageType[];
  setImages: React.Dispatch<React.SetStateAction<ImageType[]>>;
  folderName: string;
}

export default function OutputImageContainer({
  images,
  setImages,
  folderName,
}: OutputImageContainerProps) {
  const { authState } = useDashboard();
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const { showSnackbar } = useSnackbar();
  const { authenticatedFetch } = useAuthContext();

  const observer = useRef<IntersectionObserver | null>(null);
  const lastImageElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const grabImages = useCallback(
    async (page: number) => {
      if (page === 1) return;
      try {
        setLoading(true);
        const response = await authenticatedFetch('/image/getImages/', {
          method: 'GET',
          queryParams: {
            isSaved: folderName === 'saved' ? true : false,
            isDeleted: false,
            page: page,
          },
        });

        if (response.data.length === 0) {
          setHasMore(false);
        } else {
          setImages((prev) => [...prev, ...response.data]);
        }
      } catch {
        showSnackbar('Error fetching images', 'error');
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authState.userToken]
  );

  useEffect(() => {
    if (images.length < 20) return;

    grabImages(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, grabImages]);

  return (
    <div className="div_output_images" style={{ opacity: 1, display: 'block' }}>
      {images.map((image, index) => (
        <OutputImage
          key={index}
          image={image}
          setImages={setImages}
          folderName={folderName}
          ref={index === images.length - 1 ? lastImageElementRef : null}
        />
      ))}
      {loading && <div>Loading...</div>}
    </div>
  );
}
