import { useEffect, useRef, useState } from 'react';
import CreateModelRow from './CreateModelRow';
import SelectModelRow from './SelectModelRow';
import SetPreferencesRow from './SetPreferencesRow';
import TakeAPhotoRow from './TakeAPhotoRow';
import '../../styles/Dashboard/Sidebar.css';
import { useDashboard } from './DashboardContext';
import useAuth from '../../utils/useAuth';

interface Alert {
  date_created: string;
  message: string;
  id: number;
}

export default function Sidebar() {
  const { authState } = useDashboard();
  const { authenticatedFetch } = useAuth();
  const [alerts, setAlerts] = useState<Alert>();
  const trainingTabRef = useRef<HTMLDivElement>(null);
  const alertsRef = useRef<boolean>(false);

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    if (!alertsRef.current) {
      // Grab alerts
      alertsRef.current = true;
      authenticatedFetch('/user/getAlerts/', {
        method: 'GET',
      }).then((response) => {
        setAlerts(response.alerts[0]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={'sidebar'}>
      {authState.user?.models.filter((model) => !model.isPublic).length === 0 && (
        <p className="alert">
          Welcome to DreamsnapAI! Start with creating your first AI model of a person (like
          yourself), this will take about 5 minutes and then you can start doing your first
          photoshoot with your own AI model!
        </p>
      )}
      {alerts && (
        <p className="alert">
          {formatDate(alerts.date_created)}: {alerts.message}
        </p>
      )}

      <>
        <SelectModelRow trainingTabRef={trainingTabRef} />
        <SetPreferencesRow />
        <TakeAPhotoRow />
      </>

      <CreateModelRow trainingTabRef={trainingTabRef} />
      <hr className="sidebar_final_divider" />
    </div>
  );
}
