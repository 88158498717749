import PackItem from './PackItem';
import '../../../styles/Packs.css';
import { useApp } from '../../AppContext';

interface PacksListProps {
  header?: boolean;
}

export default function PacksList({ header }: PacksListProps) {
  const { photoPacks } = useApp();

  return (
    <div>
      <div id="packs" className="templates">
        {header && (
          <div>
            <h2 className="text-center">Pick from 40+ photo packs</h2>
            <p className="text-center">
              With DreamSnapAI's preset photo packs, you don't need to do any of the hard work of
              writing prompts or setting parameters. Instead, with just one click, DreamSnap AI set
              of photos for you and we keep adding new photo packs regularly.
            </p>
            <p className="text-center mt-4">
              All packs are included in your membership! You can try as many as you want.
            </p>
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
          {photoPacks.map((pack) => (
            <PackItem key={pack.slug} pack={pack} />
          ))}
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}
