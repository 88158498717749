interface TooltipProps {
  content: string;
  image?: string;
  position: { left: number; top: number };
}

export default function Tooltip({ content, position }: TooltipProps) {
  return (
    <div className="tooltip" style={{ left: position.left, top: position.top }}>
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </div>
  );
}
