import React, { createContext, useState, useContext, ReactNode } from 'react';
import Tooltip from './Tooltip';

// Define the shape of the tooltip state
interface TooltipState {
  content: string;
  image?: string;
  position: { left: number; top: number };
}

// Define the shape of the context
interface TooltipContextType {
  tooltipState: TooltipState | null;
  showTooltip: (state: TooltipState) => void;
  hideTooltip: () => void;
}

// Create the context
const TooltipContext = createContext<TooltipContextType | undefined>(undefined);

// Create a custom hook to use the tooltip context
export const useTooltip = () => {
  const context = useContext(TooltipContext);
  if (context === undefined) {
    throw new Error('useTooltip must be used within a TooltipProvider');
  }
  return context;
};

// Define props for TooltipProvider
interface TooltipProviderProps {
  children: ReactNode;
}

// Create the TooltipProvider component
export const TooltipProvider: React.FC<TooltipProviderProps> = ({ children }) => {
  const [tooltipState, setTooltipState] = useState<TooltipState | null>(null);

  const showTooltip = (state: TooltipState) => {
    setTooltipState(state);
  };

  const hideTooltip = () => {
    setTooltipState(null);
  };

  return (
    <TooltipContext.Provider value={{ tooltipState, showTooltip, hideTooltip }}>
      {children}
      {tooltipState && <Tooltip {...tooltipState} />}
    </TooltipContext.Provider>
  );
};
