import FeatureComparison from './FeatureComparison';
import SelfieFeature from './SelfieFeature';
import StudioFeature from './StudioFeature';
import Testimonials from './Testimonials';

export default function Features() {
  return (
    <div className="features" id="features">
      <StudioFeature />
      <br />
      <br />
      <br />
      <SelfieFeature />
      <br />
      <br />
      <br />
      <Testimonials />
      <br />
      <br />
      <br />
      <FeatureComparison />
    </div>
  );
}
