import { Alert, Snackbar } from '@mui/material';
import { useSnackbar } from './SnackbarContext';

export default function SnackbarComponent() {
  const { snackbarOpen, snackbarMessage, snackbarSeverity, closeSnackbar } = useSnackbar();

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={closeSnackbar}
    >
      <Alert
        onClose={closeSnackbar}
        severity={snackbarSeverity}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
  );
}
