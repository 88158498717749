import React, { createContext, useState, useContext, ReactNode } from 'react';

interface ImageViewerState {
  src: string;
  prompt: string;
  folder: string;
}

interface PhotoPrompt {
  prompt: string;
  creativity: number;
  style: string;
  emotion: string;
  cameraPosition: string;
  place: string;
  seed: string;
  photoCount: number;
  photoSize: string;
  useSeed: boolean;
}

export interface ModelType {
  modelId: string;
  requestId?: string | null;
  modelName: string;
  gender: string;
  objectType: string;
  isPublic: boolean;
  imageUrl: string;
  modelUrl: string;
  isLoading: boolean;
}

export interface PromptExample {
  promptId: number;
  prompt: string;
  imageLink: string;
}

export interface UserType {
  user: {
    id: string;
    tokens: string;
    customerId: string;
    subscription: {
      planId: number;
      planName: string;
      tokensPerMonth: number;
      aiModelsPerMonth: number;
      availableModels: number;
    };
  };
  models: ModelType[];
}

interface AuthState {
  isAuthenticated: boolean;
  user: UserType | null;
  userToken: string | null;
  isLoading: boolean;
}

export interface ImageType {
  imageId: string;
  requestId: string | null;
  userId: string;
  modelId: string;
  imageLink: string | null;
  isLoading: boolean;
  description: string;
  modelName: string;
  prompt: string;
  negativePrompt?: string;
  seed?: string;
  width: number;
  height: number;
  generateTimeMs: number;
  createdAt: string;
  upscaled: boolean;
  upscaledAt?: string;
  isSaved: boolean;
  isDeleted: boolean;
  deletedAt?: string;
  countIndex?: number;
}

interface DashboardContextType {
  userID: string;
  setUserID: (id: string) => void;
  photoPrompt: PhotoPrompt;
  setPhotoPrompt: React.Dispatch<React.SetStateAction<PhotoPrompt>>;
  imageViewerState: ImageViewerState | null;
  showImageViewer: (src: string, prompt: string, folder: string) => void;
  hideImageViewer: () => void;
  authState: AuthState;
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>;
  cameraImages: ImageType[];
  setCameraImages: React.Dispatch<React.SetStateAction<ImageType[]>>;
  savedImages: ImageType[];
  setSavedImages: React.Dispatch<React.SetStateAction<ImageType[]>>;
  promptExamples: PromptExample[];
  setPromptExamples: React.Dispatch<React.SetStateAction<PromptExample[]>>;
  selectedModel: ModelType | undefined;
  setSelectedModel: React.Dispatch<React.SetStateAction<ModelType | undefined>>;
  activeFolder: string;
  setActiveFolder: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  seed: number;
  setSeed: React.Dispatch<React.SetStateAction<number>>;
  trainingExpanded: boolean;
  setTrainingExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  takeAPhotoExpanded: boolean;
  setTakeAPhotoExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  selectModelExpanded: boolean;
  setSelectModelExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

const DashboardContext = createContext<DashboardContextType | undefined>(undefined);

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardProvider');
  }
  return context;
};

interface DashboardProviderProps {
  children: ReactNode;
}

export const DashboardProvider = ({ children }: DashboardProviderProps) => {
  const [imageViewerState, setImageViewerState] = useState<ImageViewerState | null>(null);
  const [photoPrompt, setPhotoPrompt] = useState<PhotoPrompt>({
    prompt: '',
    creativity: 3.5,
    style: '',
    emotion: '',
    cameraPosition: '',
    place: '',
    seed: '',
    photoCount: 1,
    photoSize: 'portrait_4_3',
    useSeed: false,
  });
  const [userID, setUserID] = useState<string>('');
  const [authState, setAuthState] = useState<AuthState>({
    isAuthenticated: false,
    user: null,
    userToken: null,
    isLoading: true,
  });
  const [cameraImages, setCameraImages] = useState<ImageType[]>([]);
  const [savedImages, setSavedImages] = useState<ImageType[]>([]);
  const [promptExamples, setPromptExamples] = useState<PromptExample[]>([]);
  const [selectedModel, setSelectedModel] = useState<ModelType | undefined>();
  const [activeFolder, setActiveFolder] = useState<string>('camera');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [seed, setSeed] = useState<number>(0);
  const [trainingExpanded, setTrainingExpanded] = useState<boolean>(false);
  const [takeAPhotoExpanded, setTakeAPhotoExpanded] = useState<boolean>(false);
  const [selectModelExpanded, setSelectModelExpanded] = useState<boolean>(false);

  const showImageViewer = (src: string, prompt: string, folder: string) => {
    setImageViewerState({ src, prompt, folder });
  };

  const hideImageViewer = () => {
    setImageViewerState(null);
  };

  return (
    <DashboardContext.Provider
      value={{
        userID,
        setUserID,
        photoPrompt,
        setPhotoPrompt,
        imageViewerState,
        showImageViewer,
        hideImageViewer,
        authState,
        setAuthState,
        cameraImages,
        setCameraImages,
        savedImages,
        setSavedImages,
        promptExamples,
        setPromptExamples,
        selectedModel,
        setSelectedModel,
        activeFolder,
        setActiveFolder,
        isLoading,
        setIsLoading,
        seed,
        setSeed,
        trainingExpanded,
        setTrainingExpanded,
        takeAPhotoExpanded,
        setTakeAPhotoExpanded,
        selectModelExpanded,
        setSelectModelExpanded,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
