import { useEffect, useRef } from 'react';
import '../../styles/Dashboard/ImageViewer.css';
import { ImageType, useDashboard } from './DashboardContext';
import { useSnackbar } from '../SnackbarContext';

export default function ImageViewer() {
  const {
    hideImageViewer,
    imageViewerState,
    setPhotoPrompt,
    cameraImages,
    savedImages,
    showImageViewer,
  } = useDashboard();
  const { showSnackbar } = useSnackbar();
  const backdropRef = useRef<HTMLDivElement>(null);
  const buttonsRef = useRef<HTMLDivElement>(null);
  const leftArrowRef = useRef<HTMLDivElement>(null);
  const rightArrowRef = useRef<HTMLDivElement>(null);

  const handleDownload = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!imageViewerState?.src) {
      showSnackbar('An error occurred. Please try again.', 'error');
      return;
    }

    try {
      const workerUrl = 'https://image.dreamsnapai.com';
      const response = await fetch(`${workerUrl}?url=${encodeURIComponent(imageViewerState.src)}`);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get('Content-Type');
      if (!contentType || !contentType.startsWith('image/')) {
        throw new Error('The response is not an image');
      }

      // Get the filename from the Content-Disposition header, if available
      const contentDisposition = response.headers.get('Content-Disposition');
      let filename = 'image.jpg';
      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?(.+)"?/i);
        if (filenameMatch) {
          filename = filenameMatch[1];
        }
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      // Use click() directly on the link
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
    } catch {
      showSnackbar('An error occurred while downloading the image. Please try again.', 'error');
    }
  };

  const handleArrowClick = (direction: 'left' | 'right') => {
    if (!imageViewerState) return;
    let images: ImageType[] = [];
    if (imageViewerState.folder === 'camera') {
      images = cameraImages;
    } else if (imageViewerState.folder === 'saved') {
      images = savedImages;
    }

    const currentIndex = images.findIndex((image) => image.imageLink === imageViewerState.src);
    const totalImages = images.length;

    let nextIndex = direction === 'left' ? currentIndex - 1 : currentIndex + 1;
    if (nextIndex < 0) {
      nextIndex = totalImages - 1;
    } else if (nextIndex >= totalImages) {
      nextIndex = 0;
    }

    const nextImage = images[nextIndex];
    showImageViewer(nextImage.imageLink || '', nextImage.prompt, imageViewerState.folder);
  };

  const handleCopyPrompt = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setPhotoPrompt((prev) => ({ ...prev, prompt: imageViewerState?.prompt || '' }));
    showSnackbar("Copied to 'Take a photo' input", 'success');
    hideImageViewer();
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        backdropRef.current &&
        backdropRef.current.contains(event.target as Node) &&
        buttonsRef.current &&
        !buttonsRef.current.contains(event.target as Node) &&
        leftArrowRef.current &&
        !leftArrowRef.current.contains(event.target as Node) &&
        rightArrowRef.current &&
        !rightArrowRef.current.contains(event.target as Node)
      ) {
        hideImageViewer();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageViewerState]);

  if (!imageViewerState) return null;

  return (
    <div>
      <div className="image_viewer saved" style={{ display: 'block' }}>
        <span className="action-save-photo saved">
          <svg
            aria-hidden="true"
            width="35"
            focusable="false"
            data-prefix="fas"
            data-icon="heart"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            className="svg-inline--fa fa-heart fa-w-16 fa-9x"
          >
            <path d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path>
          </svg>
        </span>
        <span className="action-trash-photo ">
          <svg width="35" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill="#fff"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.10002 5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H4.06055L4.88474 20.1871C4.98356 21.7682 6.29471 23 7.8789 23H16.1211C17.7053 23 19.0164 21.7682 19.1153 20.1871L19.9395 7H21C21.5523 7 22 6.55228 22 6C22 5.44772 21.5523 5 21 5H19.0073C19.0018 4.99995 18.9963 4.99995 18.9908 5H16.9C16.4367 2.71776 14.419 1 12 1C9.58104 1 7.56329 2.71776 7.10002 5ZM9.17071 5H14.8293C14.4175 3.83481 13.3062 3 12 3C10.6938 3 9.58254 3.83481 9.17071 5ZM17.9355 7H6.06445L6.88085 20.0624C6.91379 20.5894 7.35084 21 7.8789 21H16.1211C16.6492 21 17.0862 20.5894 17.1192 20.0624L17.9355 7ZM14.279 10.0097C14.83 10.0483 15.2454 10.5261 15.2068 11.0771L14.7883 17.0624C14.7498 17.6134 14.2719 18.0288 13.721 17.9903C13.17 17.9517 12.7546 17.4739 12.7932 16.9229L13.2117 10.9376C13.2502 10.3866 13.7281 9.97122 14.279 10.0097ZM9.721 10.0098C10.2719 9.97125 10.7498 10.3866 10.7883 10.9376L11.2069 16.923C11.2454 17.4739 10.83 17.9518 10.2791 17.9903C9.72811 18.0288 9.25026 17.6134 9.21173 17.0625L8.79319 11.0771C8.75467 10.5262 9.17006 10.0483 9.721 10.0098Z"
            ></path>
          </svg>
        </span>
        <img
          className=""
          src={imageViewerState.src}
          style={{
            backgroundImage: `url(${imageViewerState.src})`,
          }}
        />
      </div>
      <div className="image_viewer_backdrop" style={{ display: 'block' }} ref={backdropRef}>
        <div className="image_viewer_buttons" ref={buttonsRef}>
          <div className="action-download-image" onClick={handleDownload}>
            <span></span>
            <br />
            Download
          </div>
          <div className="action-copy-prompt" onClick={handleCopyPrompt}>
            <span></span>
            <br />
            Copy prompt
          </div>
        </div>
        <div
          className="image_viewer_arrow_left"
          ref={leftArrowRef}
          onClick={() => handleArrowClick('left')}
        >
          <svg viewBox="0 -256 1792 1792" id="svg3013" version="1.1" width="100%" height="100%">
            <defs id="defs3021"></defs>{' '}
            <g transform="matrix(1,0,0,-1,387.25424,1338.5763)" id="g3015">
              {' '}
              <path
                d="M 742,-37 90,614 Q 53,651 53,704.5 53,758 90,795 l 652,651 q 37,37 90.5,37 53.5,0 90.5,-37 l 75,-75 q 37,-37 37,-90.5 0,-53.5 -37,-90.5 L 512,704 998,219 q 37,-38 37,-91 0,-53 -37,-90 L 923,-37 Q 886,-74 832.5,-74 779,-74 742,-37 z"
                id="path3017"
                style={{ fill: '#fff' }}
              ></path>{' '}
            </g>
          </svg>
        </div>
        <div
          className="image_viewer_arrow_right"
          ref={rightArrowRef}
          onClick={() => handleArrowClick('right')}
        >
          <svg viewBox="0 -256 1792 1792" id="svg3025" version="1.1" width="100%" height="100%">
            <defs id="defs3033"></defs>
            <g transform="matrix(1,0,0,-1,349.28814,1330.9831)" id="g3027">
              <path
                d="m 1099,704 q 0,-52 -37,-91 L 410,-38 q -37,-37 -90,-37 -53,0 -90,37 l -76,75 q -37,39 -37,91 0,53 37,90 l 486,486 -486,485 q -37,39 -37,91 0,53 37,90 l 76,75 q 36,38 90,38 54,0 90,-38 l 652,-651 q 37,-37 37,-90 z"
                id="path3029"
                style={{ fill: '#fff' }}
              ></path>
            </g>
          </svg>
        </div>
      </div>
    </div>
  );
}
