import { useEffect, useRef } from 'react';
import '../../styles/Dashboard/LeftNavMenu.css';
import { auth } from '../../firebase';
import { useDashboard } from './DashboardContext';
import { useNavigate } from 'react-router-dom';

interface LeftMenuNavProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isMenuOpen: boolean) => void;
  headerButtonRef: React.RefObject<HTMLDivElement>;
  menuPosition: string;
}

export default function LeftMenuNav({
  isMenuOpen,
  setIsMenuOpen,
  headerButtonRef,
  menuPosition,
}: LeftMenuNavProps) {
  const { setAuthState, setTakeAPhotoExpanded, setTrainingExpanded } = useDashboard();
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        headerButtonRef.current &&
        !headerButtonRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpgrade = async () => {
    navigate('/pricing');
  };

  const handleBilling = async () => {
    navigate('/billing');
  };

  const handleStudio = () => {
    setTakeAPhotoExpanded(true);
  };

  const handleCreateModel = () => {
    setTrainingExpanded(true);
  };

  const logOut = async () => {
    await auth.signOut();
    setAuthState({
      isAuthenticated: false,
      user: null,
      userToken: null,
      isLoading: false,
    });
  };

  return (
    <div ref={menuRef} className={`menu-nav ${menuPosition} ${isMenuOpen ? 'show' : ''}`}>
      <a onClick={handleUpgrade}>
        <strong>⚡️&nbsp;&nbsp;&nbsp;&nbsp;Get more credits</strong>
      </a>

      <a onClick={handleCreateModel}>✨&nbsp;&nbsp;&nbsp;&nbsp;Create new AI model</a>

      <a onClick={handleStudio}>📸&nbsp;&nbsp;&nbsp;&nbsp;Studio</a>

      <hr />

      <a onClick={handleBilling}>💳&nbsp;&nbsp;&nbsp;&nbsp;Billing</a>

      <a target="_blank" href="/legal">
        🧑‍⚖️&nbsp;&nbsp;&nbsp;&nbsp;TOS &amp; Privacy
      </a>

      <a onClick={logOut} className="action-log-out">
        🚪&nbsp;&nbsp;&nbsp;&nbsp;Log out
      </a>
    </div>
  );
}
