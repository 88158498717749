import '../../../styles/MainExample.css';
import { useApp } from '../../AppContext';

export default function MainOutputImageExamples() {
  const { currentImages } = useApp();

  const OutputImage = (index: number, imgSrc: string) => {
    return (
      <div key={index} className="div_output_image finished">
        <div className="div_output_image_container">
          <img
            width={512}
            height={768}
            className="output_image"
            src={`https://imagetransform.dreamsnapai.com/${imgSrc}?format=auto,fit=cover,width=512,height=768,quality=85`}
          ></img>
        </div>
      </div>
    );
  };

  return (
    <div
      id="imageExamples"
      className="div_output_images examples"
      style={{ display: 'block', opacity: 1 }}
    >
      {currentImages.map((img, index) => OutputImage(index, img))}
    </div>
  );
}
