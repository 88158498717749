import { useCallback, useEffect, useRef } from 'react';
import { ModelType, useDashboard } from '../DashboardContext';
import LoadingSpinner from '../MainContent/LoadingSpinner';
import CustomFetch from '../../../utils/FetchHook';
import { useSnackbar } from '../../SnackbarContext';
import { useAuthContext } from '../../AuthContext';

interface DisplayModelProps {
  model: ModelType;
  selectedModel: ModelType | undefined;
  setSelectedModel: React.Dispatch<React.SetStateAction<ModelType | undefined>>;
}

export default function DisplayModel({
  model,
  setSelectedModel,
  selectedModel,
}: DisplayModelProps) {
  const { authState, setAuthState } = useDashboard();
  const { showSnackbar } = useSnackbar();
  const { authenticatedFetch } = useAuthContext();
  const errorCount = useRef(0);

  const fetchData = useCallback(async () => {
    if (!model.isLoading) {
      return 'finished';
    }

    try {
      const response = await authenticatedFetch('/inference/checkRequestStatus', {
        method: 'POST',
        body: JSON.stringify({ requestId: model.requestId, createModel: true }),
      });

      if (response.status === 'in_progress') {
        return 'in_progress';
      }

      setAuthState((prevAuthState) => ({
        ...prevAuthState,
        user: {
          ...prevAuthState.user!,
          models: prevAuthState.user!.models.map((prevModel) =>
            prevModel.modelId === model.modelId
              ? {
                  ...prevModel,
                  modelUrl: response.status.diffusers_lora_file.url,
                  imageUrl: response.imageUrl,
                  isLoading: false,
                }
              : prevModel
          ),
        },
      }));

      return 'finished';
    } catch {
      showSnackbar('Error fetching data', 'error');
      return 'error';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model, authState.userToken, CustomFetch, setAuthState]);

  useEffect(() => {
    const intervalId: NodeJS.Timeout = setInterval(async () => {
      const result = await fetchData();
      if (result === 'finished' || result === 'error') {
        errorCount.current += 1;
        if (result === 'error' && errorCount.current < 3) return;
        clearInterval(intervalId);
      }
    }, 30000);

    // Initial fetch
    fetchData();

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  return (
    <div
      className={`model_sample ${selectedModel?.modelId === model.modelId ? 'active' : ''} ${model.isLoading ? 'loading' : ''}`}
      onClick={!model.isLoading ? () => setSelectedModel(model) : undefined}
    >
      {model.isLoading ? (
        <div
          style={{
            height: '100px',
            width: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingSpinner size={30} />
        </div>
      ) : (
        <img
          className="model_sample_img"
          width="100"
          height="100"
          loading="lazy"
          src={model.imageUrl}
          style={{ width: '100%', display: 'block' }}
        />
      )}
      <div className="model_sample_meta">{model.modelName}</div>
    </div>
  );
}
