import '../../../styles/Features.css';

export default function SelfieFeature() {
  return (
    <div className="feature">
      <h2 id="selfies" style={{ textAlign: 'center' }}>
        Upload your selfies and start taking AI photos now
      </h2>
      <br />
      <div className="frontpage_training_example">
        <div className="frontpage_training_example_input">
          <img
            loading="lazy"
            src="trainingExamples/trainingExample1.jpg"
            alt="Training example 1"
          />
          <img
            loading="lazy"
            src="trainingExamples/trainingExample2.jpg"
            alt="Training example 2"
          />
          <img
            loading="lazy"
            src="trainingExamples/trainingExample3.jpg"
            alt="Training example 3"
          />
          <img
            loading="lazy"
            src="trainingExamples/trainingExample4.jpg"
            alt="Training example 4"
          />
        </div>
        <img
          className="frontpage_training_example_arrow"
          src="trainingExamples/pencil-arrow.avif"
          alt="Arrow"
        />
        <div className="frontpage_training_example_output">
          <div className="top_right_label">AI generated photo</div>
          <img loading="lazy" src="trainingExamples/outputExample.png" alt="AI generated photo" />
        </div>
      </div>
    </div>
  );
}
