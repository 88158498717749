import '../../../styles/Footer.css';
import { useApp } from '../../AppContext';

interface FooterColumnProps {
  title?: string;
  links: { href: string; text: string; target?: string; rel?: string; onClick?: () => void }[];
}

const fixName = (name: string) => {
  return name
    .replace(/-/g, ' ') // Replace hyphens with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()) // Capitalize each word
    .replace('Ai ', 'AI '); // Capitalize AI
};

const FooterColumn = ({ title, links }: FooterColumnProps) => (
  <div className="footer-links-column">
    {title && <h4>{title}</h4>}
    {links.map((link, index) => (
      <p key={index}>
        <a
          tabIndex={-1}
          href={link.href}
          target={link.target}
          rel={link.rel}
          onClick={link.onClick}
        >
          {link.text}
        </a>
      </p>
    ))}
  </div>
);

export default function Footer() {
  const { photoPacks } = useApp();
  const scrollToTopAndFocus = () => {
    window.scrollTo(0, 0);
  };

  const photoShootsLinks1 = photoPacks.map((photoPack) => ({
    href: `${photoPack.href}`,
    text: fixName(photoPack.slug),
  }));

  const pagesLinks = [
    { href: '', text: 'Sign up or Log in', onClick: scrollToTopAndFocus },
    { href: '/faq', text: 'FAQ' },
    {
      href:
        process.env.NODE_ENV === 'development'
          ? 'https://billing.stripe.com/p/login/test_28odSXfBRe8B6ZybII'
          : 'https://billing.stripe.com/p/login/eVa2c26LUgkW3wk288',
      text: 'Billing',
    },
  ];

  return (
    <div className="footer-links" style={{ paddingBottom: '200px' }}>
      <div className="footer-links-column">
        <div className="logo">
          <a>
            <h1
              style={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 300,
                whiteSpace: 'nowrap',
                margin: 0,
              }}
            >
              <div className="logo-box" />
              <span style={{ fontWeight: 'bold' }}>DreamSnap</span>AI
            </h1>
          </a>
        </div>
      </div>

      <FooterColumn title="Photo Shoots with AI™" links={photoShootsLinks1} />

      <FooterColumn title="Pages" links={pagesLinks} />

      <div style={{ clear: 'both' }}></div>
    </div>
  );
}
