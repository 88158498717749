import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/Processing.css';
import { useAuthContext } from '../components/AuthContext';
import { useDashboard } from '../components/Dashboard/DashboardContext';
import DocumentTitle from '../utils/DocumentTitle';

interface ProcessingProps {
  title: string;
}

export default function Processing({ title }: ProcessingProps) {
  const [status, setStatus] = useState('processing');
  const [dots, setDots] = useState('');
  const { sessionId } = useParams();
  const { authState, setAuthState } = useDashboard();
  const { authenticatedFetch } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionId) {
      navigate('/dashboard');
    }
    const checkStatus = async () => {
      if (!authState.userToken) return;
      try {
        const data = await authenticatedFetch('/payment/checkTransactionStatus', {
          method: 'GET',
          queryParams: { sessionId },
        });

        if (data.status === 'completed') {
          // Grab the updated user data
          const userData = await authenticatedFetch('/user/getUserData', {
            method: 'GET',
          });
          setAuthState({
            ...authState,
            isAuthenticated: true,
            user: userData,
          });
          setStatus('success');
          navigate('/dashboard');
        } else if (data.status === 'failed') {
          setStatus('failed');
        } else {
          // If still processing, check again in 2 seconds
          setTimeout(checkStatus, 2000);
        }
      } catch (error) {
        console.error('Error checking payment status:', error);
        setStatus('error');
      }
    };

    checkStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, authState]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDots((prev) => (prev.length >= 3 ? '' : prev + '.'));
    }, 500);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="processing-container">
      <DocumentTitle title={title} />
      <div className={`processing-card ${(status === 'failed' || status === 'error') && 'error'}`}>
        {status === 'processing' && (
          <>
            <h2 className="processing-heading animated-dots">Processing Your Order</h2>
            <p className="processing-paragraph">Please wait while we confirm your payment{dots}</p>
          </>
        )}
        {status === 'success' && (
          <>
            <h2 className="processing-heading success-heading">Payment Successful!</h2>
            <p className="processing-paragraph">
              Thank you for your purchase. Redirecting to dashboard{dots}
            </p>
          </>
        )}
        {status === 'failed' && (
          <>
            <h2 className="processing-heading error-heading">Payment Failed</h2>
            <p className="processing-paragraph">
              There was an issue with your payment. Please try again.
            </p>
          </>
        )}
        {status === 'error' && (
          <>
            <h2 className="processing-heading error-heading">Error</h2>
            <p className="processing-paragraph">An error occurred. Please contact support.</p>
          </>
        )}
      </div>
    </div>
  );
}
