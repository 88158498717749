import React, { createContext, useState, useContext, ReactNode } from 'react';

export interface PhotoPack {
  id: number;
  href: string;
  slug: string;
  name: string;
  description: string;
  photoCount: number;
  usedCount: number;
  isNew: boolean;
  imageUrls: string[];
  prompts: [
    {
      prompt: string;
      count: number;
    },
  ];
}

type Feature = {
  text: string;
  strong: boolean;
};

export interface Pricing {
  planId: number;
  planName: string;
  description: Feature[];
  monthlyPrice: number;
  yearlyPrice: number;
  stripePriceIdMonthly: string;
  stripePriceIdYearly: string;
  tokensPerMonth: number;
  concurrentPhotos: number;
  customPrompts: boolean;
  aiModelsPerMonth: number;
}

interface AppContextType {
  photoPacks: PhotoPack[];
  setPhotoPacks: React.Dispatch<React.SetStateAction<PhotoPack[]>>;
  currentImages: string[];
  setCurrentImages: React.Dispatch<React.SetStateAction<string[]>>;
  pricing: Pricing[];
  setPricing: React.Dispatch<React.SetStateAction<Pricing[]>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useApp = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useApp must be used within a AppProvider');
  }
  return context;
};

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const [photoPacks, setPhotoPacks] = useState<PhotoPack[]>([]);
  const [currentImages, setCurrentImages] = useState<string[]>([]);
  const [pricing, setPricing] = useState<Pricing[]>([]);

  return (
    <AppContext.Provider
      value={{
        photoPacks,
        setPhotoPacks,
        currentImages,
        setCurrentImages,
        pricing,
        setPricing,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
