import { useCallback, useEffect, useRef, useState } from 'react';
import { useDashboard, UserType } from '../components/Dashboard/DashboardContext';
import {
  onAuthStateChanged,
  User as FirebaseUser,
  setPersistence,
  browserLocalPersistence,
} from 'firebase/auth';
import customFetch from './FetchHook';
import { auth } from '../firebase';

export default function useAuth() {
  const { authState, setAuthState } = useDashboard();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const lastFetchedUid = useRef<string | null>(null);
  const persistenceSet = useRef(false);

  const refreshToken = useCallback(async () => {
    if (!auth.currentUser) {
      throw new Error('No user is currently signed in');
    }
    setIsRefreshing(true);
    try {
      console.log('Refreshing token...');
      const newToken = await auth.currentUser.getIdToken(true);
      setAuthState((prev) => ({
        ...prev,
        userToken: newToken,
      }));
      return newToken;
    } catch (error) {
      console.error('Failed to refresh token:', error);
      throw error;
    } finally {
      setTimeout(() => setIsRefreshing(false), 1000);
    }
  }, [setAuthState]);

  const authenticatedFetch = useCallback(
    async (endpoint: string, options = {}) => {
      try {
        const result = await customFetch({
          endpoint,
          options,
          token: authState.userToken!,
        });
        return result;
      } catch (error) {
        console.error('Error in authenticated fetch:', error);
        if (error instanceof Error && error.message.includes('Invalid token')) {
          if (!isRefreshing) {
            const newToken = await refreshToken();
            // Retry the request with the new token
            return customFetch({
              endpoint,
              options,
              token: newToken,
            });
          } else {
            // Wait for the ongoing refresh to complete and then retry
            await new Promise((resolve) => {
              const checkRefresh = () => {
                if (!isRefreshing) {
                  resolve(null);
                } else {
                  setTimeout(checkRefresh, 100);
                }
              };
              checkRefresh();
            });
            return customFetch({
              endpoint,
              options,
              token: authState.userToken!,
            });
          }
        }
        throw error;
      }
    },
    [authState.userToken, isRefreshing, refreshToken]
  );

  const getUserData = useCallback(async (token: string): Promise<UserType> => {
    if (!auth.currentUser) {
      return {} as UserType;
    }
    console.log('Fetching user data...');
    const response = await customFetch({
      endpoint: '/user/getUserData/',
      options: { method: 'GET' },
      token,
    });

    if (!response) {
      throw new Error('Failed to fetch user data');
    }

    return response as UserType;
  }, []);

  useEffect(() => {
    let isMounted = true;
    let isInitialCheck = true;

    const setupPersistence = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
        console.log('Firebase persistence set successfully');
        persistenceSet.current = true;
      } catch (error) {
        console.error('Failed to set persistence:', error);
      }
    };

    const handleAuthStateChange = async (currentUser: FirebaseUser | null) => {
      if (!isMounted) {
        console.log('Component unmounted, skipping auth state change');
        return;
      }

      if (!persistenceSet.current) {
        console.log('Waiting for persistence setup...');
        await setupPersistence();
      }

      // Add a small delay on initial check for Safari
      if (isInitialCheck) {
        isInitialCheck = false;
        await new Promise((resolve) => setTimeout(resolve, 100));
      }

      if (currentUser) {
        if (
          authState.isAuthenticated &&
          authState.user &&
          authState.user.user.id === currentUser.uid
        ) {
          console.log('User data already in context, skipping API call');
          return;
        }

        if (lastFetchedUid.current === currentUser.uid) {
          console.log('User data already fetched, skipping API call');
          return;
        }

        try {
          const token = await currentUser.getIdToken(false);
          const userData = await getUserData(token);

          if (isMounted) {
            // Go through and modify the models to add isLoading property ( if no image url then isLoading is true)
            userData.models = userData.models.map((model) => ({
              ...model,
              isLoading: !model.imageUrl,
            }));

            setAuthState({
              isAuthenticated: true,
              user: userData,
              userToken: token,
              isLoading: false,
            });
            lastFetchedUid.current = currentUser.uid;
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          if (isMounted) {
            setAuthState((prev) => ({
              ...prev,
              isLoading: false,
              error: 'Failed to fetch user data',
            }));
          }
        }
      } else {
        if (authState.isAuthenticated || authState.isLoading) {
          setAuthState({
            isAuthenticated: false,
            user: null,
            userToken: null,
            isLoading: false,
          });
        }
      }
    };

    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChange);

    return () => {
      isMounted = false;
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAuthState, getUserData]);

  return { ...authState, refreshToken, authenticatedFetch };
}
