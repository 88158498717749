import '../../styles/Dashboard/GenerateButton.css';
import { useAuthContext } from '../AuthContext';
import { useDashboard } from './DashboardContext';

export default function GenerateButton() {
  const {
    photoPrompt,
    selectedModel,
    authState,
    setCameraImages,
    setIsLoading,
    setActiveFolder,
    setAuthState,
  } = useDashboard();
  const { authenticatedFetch } = useAuthContext();

  const handleGenerate = async () => {
    const { requestId } = await authenticatedFetch('/inference/takePhoto', {
      method: 'POST',
      body: JSON.stringify({
        modelUrl: selectedModel?.modelUrl,
        prompt: photoPrompt.prompt,
        modelId: selectedModel?.modelId,
        modelName: selectedModel?.modelName,
        imageSize: photoPrompt.photoSize,
        photoCount: photoPrompt.photoCount,
        negativePrompt: '',
        seed: photoPrompt.useSeed ? photoPrompt.seed : '',
        cfg: photoPrompt.creativity,
      }),
    });

    // Active loading bar
    setIsLoading(true);

    // Create placeholder loading images
    const tempImages = Array.from({ length: photoPrompt.photoCount }, (_, index) => ({
      imageId: Math.random().toString(36).substring(7),
      requestId: requestId,
      userId: authState.user?.user.id || '',
      modelId: selectedModel?.modelId || '',
      imageLink: '',
      isLoading: true,
      description: '',
      modelName: selectedModel?.modelName || '',
      prompt: photoPrompt.prompt,
      negativePrompt: '',
      seed: '',
      width: 512,
      height: 768,
      generateTimeMs: 0,
      createdAt: new Date().toISOString(),
      upscaled: false,
      isSaved: false,
      isDeleted: false,
      countIndex: index,
    }));

    // Update camera images with loading images
    setCameraImages((prev) => [...tempImages, ...prev]);
    setActiveFolder('camera');

    // Update auth state tokens
    const newTokens = (Number(authState.user?.user.tokens) || 0) - photoPrompt.photoCount;
    setAuthState((prevAuthState) => ({
      ...prevAuthState,
      user: prevAuthState.user
        ? {
            ...prevAuthState.user,
            user: {
              ...prevAuthState.user.user,
              tokens: newTokens.toString(),
            },
          }
        : null,
    }));
  };

  return (
    <div
      className="div_generate_button_container"
      onClick={
        Number(authState.user?.user.tokens) < photoPrompt.photoCount ? undefined : handleGenerate
      }
    >
      <div
        style={{ marginLeft: 0 }}
        tabIndex={100}
        className={`button action-take-photo ${Number(authState.user?.user.tokens) < photoPrompt.photoCount ? 'disabled' : ''}`}
      >
        ⏎{' '}
        <span className="button-label">
          Take {photoPrompt.photoCount} photo{photoPrompt.photoCount > 1 ? 's' : ''}
        </span>{' '}
        (~
        <span className="avgWaitingTime">10s</span>)
      </div>
    </div>
  );
}
